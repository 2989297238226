import axios from 'axios'

import httpClient from '../utils/httpClient'

export const getDownloadUrl = async (documentId: number) => {
  const response = await httpClient.post(`/document/${documentId}/download`)

  return response.data
}

export const getUploadUrl = async (fileName: string) => {
  const response = await httpClient.post('/document/upload', { fileName })

  return response.data
}

export const uploadFile = async (url: string, file: File) => {
  const response = await axios.put(url, file)

  return response.data
}

export const process = async (documentId: number) => {
  const response = await httpClient.post(`/document/${documentId}/process`)

  return response.data
}
