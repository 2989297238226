import httpClient from '../utils/httpClient'

export async function updateAccount({
  accountId,
  categoryId,
  noiDefinitionIds,
}) {
  await httpClient.patch(`/account/${accountId}/update`, {
    categoryId,
    noiDefinitionIds,
  })
}
