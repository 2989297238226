import _ from 'lodash'
import moment, { MomentInput } from 'moment'
import 'moment/locale/da'

interface IntervalFormatOptions {
  locale?: string
}

export const formatInterval = (
  year: number,
  interval: number,
  byInterval: 'month' | 'quarter',
  { locale = navigator.language }: IntervalFormatOptions = {}
) => {
  const m = moment.utc()

  m.locale(locale)
  m.year(year)

  if (byInterval === 'month') {
    m.month(interval - 1) // month() indexes from 0
    return `${m.format('MMM')} ’${m.format('YY')}`
  } else if (byInterval === 'quarter') {
    m.quarter(interval)
    return `Q${m.format('Q')} ’${m.format('YY')}`
  }

  return `${year}-${interval}`
}

interface DateFormatOptions {
  format?: string
  defaultValue?: string
  locale?: string
}

export const formatDate = (
  date: MomentInput,
  {
    format = 'LL',
    defaultValue = '-',
    locale = navigator.language,
  }: DateFormatOptions = {}
) => {
  if (!date) {
    return defaultValue
  }

  const m = moment(date)
  m.locale(locale)

  return m.format(format)
}

export interface NumberFormatOptions {
  signDisplay?: 'auto' | 'always' | 'exceptZero' | 'negative' | 'never'
  currency?: string
  decimals?: number
  locale?: string
}

export const formatNumber = (
  number: number | null,
  { signDisplay, currency, decimals = 2, locale }: NumberFormatOptions = {}
) => {
  if (number === null) {
    return number
  }

  if (!locale) {
    locale = navigator.language
  }

  let currencyOptions = {}
  if (currency) {
    currencyOptions = {
      style: 'currency',
      currencyDisplay: 'narrowSymbol',
      currency,
    }
  }

  return new Intl.NumberFormat(locale, {
    signDisplay,
    minimumFractionDigits: decimals,
    maximumFractionDigits: decimals,
    trailingZeroDisplay: 'stripIfInteger',
    ...currencyOptions,
  }).format(number)
}

export const getGridValueFormatter = (
  field: string,
  options: NumberFormatOptions = {}
) => {
  return function (params: Record<string, unknown>) {
    const value = _.get(params, ['data', field])

    return formatNumber(value, options)
  }
}
