import { ComponentPropsWithoutRef } from 'react'

const FormValue = ({ children }: ComponentPropsWithoutRef<'div'>) => {
  return (
    <div className="mt-2">
      <span className="block w-full text-gray-900 sm:text-sm sm:leading-6">
        {children}
      </span>
    </div>
  )
}

const FormLabel = ({
  children,
  ...props
}: ComponentPropsWithoutRef<'label'>) => {
  return (
    <label
      className="block text-sm font-medium leading-6 text-gray-900"
      {...props}
    >
      {children}
    </label>
  )
}

interface FormFieldProps extends ComponentPropsWithoutRef<'div'> {
  columns?: number
}

const COL_SPANS: Record<number, string> = {
  1: 'sm:col-span-1',
  2: 'sm:col-span-2',
  3: 'sm:col-span-3',
  4: 'sm:col-span-4',
  5: 'sm:col-span-5',
  6: 'sm:col-span-6',
}

// A single field in the form
const FormField = ({ children, columns = 3 }: FormFieldProps) => {
  return <div className={COL_SPANS[columns]}>{children}</div>
}

const FormFieldGrid = ({ children }: ComponentPropsWithoutRef<'div'>) => {
  return (
    <div className="mt-6 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
      {children}
    </div>
  )
}

interface FormSectionProps extends ComponentPropsWithoutRef<'div'> {
  title?: string
  subtitle?: string
}

// A section contains a 6-col grid of fields
const FormSection = ({ title, subtitle, children }: FormSectionProps) => {
  return (
    <div className="border-b border-gray-900/10 pb-12">
      {title && (
        <h3 className="text-base font-semibold leading-7 text-gray-900">
          {title}
        </h3>
      )}
      {subtitle && (
        <p className="mt-1 text-sm leading-6 text-gray-600">{subtitle}</p>
      )}
      <div className="mt-10">{children}</div>
    </div>
  )
}

// The overall form component spacing out sections
const Form = ({ children, ...props }: ComponentPropsWithoutRef<'form'>) => {
  return (
    <form {...props}>
      <div className="space-y-6">{children}</div>
    </form>
  )
}

export { Form, FormSection, FormFieldGrid, FormField, FormLabel, FormValue }
