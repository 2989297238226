import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model'
import { ExcelExportModule } from '@ag-grid-enterprise/excel-export'
import { RichSelectModule } from '@ag-grid-enterprise/rich-select'
import PropTypes from 'prop-types'
import { forwardRef, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import useResizeObserver from 'use-resize-observer'

import useNumberOptions from '../../hooks/useNumberOptions'
import { getGridValueFormatter } from '../../utils/format'
import AgGridReact from '../Grid/Grid'
import Page from '../Page/LegacyPage'

const ZeroProof = forwardRef(({ data }, gridRef) => {
  const { t } = useTranslation(['dashboard'])

  const numberOptions = useNumberOptions()

  useResizeObserver({
    ref: document.body,
    onResize: () => {
      if (gridRef.current.api) {
        gridRef.current.api.sizeColumnsToFit({
          defaultMinWidth: 160,
        })
      }
    },
  })

  const onFirstDataRendered = useCallback(() => {
    if (gridRef.current) {
      gridRef.current.api.sizeColumnsToFit({
        defaultMinWidth: 160,
      })
    }
  }, [])

  const gridOptions = useMemo(
    () => ({
      columnDefs: [
        {
          headerName: 'Building',
          width: 50,
          field: 'buildingReference',
        },
        {
          headerName: 'Year',
          width: 25,
          field: 'year',
          cellClass: ['c-grid-cell', 'c-grid-cell--numeric'],
        },
        {
          headerName: 'Operating income',
          width: 150,
          field: 'operating_income',
          cellClass: ['c-grid-cell', 'c-grid-cell--numeric'],
          valueFormatter: getGridValueFormatter(
            'operating_income',
            numberOptions
          ),
        },
        {
          headerName: 'Operating expenses',
          width: 150,
          field: 'operating_expense',
          cellClass: ['c-grid-cell', 'c-grid-cell--numeric'],
          valueFormatter: getGridValueFormatter(
            'operating_expense',
            numberOptions
          ),
        },
        {
          headerName: 'Financial income',
          width: 250,
          field: 'financial_income',
          cellClass: ['c-grid-cell', 'c-grid-cell--numeric'],
          valueFormatter: getGridValueFormatter(
            'financial_income',
            numberOptions
          ),
        },
        {
          headerName: 'Financial expenses',
          width: 250,
          field: 'financial_expense',
          cellClass: ['c-grid-cell', 'c-grid-cell--numeric'],
          valueFormatter: getGridValueFormatter(
            'financial_expense',
            numberOptions
          ),
        },
        {
          headerName: 'Result',
          width: 50,
          field: 'result',
          cellClass: ['c-grid-cell', 'c-grid-cell--numeric'],
          valueFormatter: getGridValueFormatter('result', numberOptions),
        },
        {
          headerName: 'Assets change',
          width: 50,
          field: 'asset',
          valueFormatter: getGridValueFormatter('asset', numberOptions),
        },
        {
          headerName: 'Liabilities change',
          width: 50,
          field: 'liability',
          cellClass: ['c-grid-cell', 'c-grid-cell--numeric'],
          valueFormatter: getGridValueFormatter('liability', numberOptions),
        },
        {
          headerName: 'Net balance increase',
          width: 50,
          field: 'netBalanceIncrease',
          cellClass: ['c-grid-cell', 'c-grid-cell--numeric'],
          valueFormatter: getGridValueFormatter(
            'netBalanceIncrease',
            numberOptions
          ),
        },
        {
          headerName: 'Sum check',
          width: 50,
          field: 'sumCheck',
          cellClass: ['c-grid-cell', 'c-grid-cell--numeric'],
          valueFormatter: getGridValueFormatter('sumCheck', numberOptions),
        },
      ],
    }),
    [data, numberOptions]
  )

  return (
    <>
      <Page.Section.Title>{t('zeroProof.title')}</Page.Section.Title>
      <Page.Section.Content className="ag-theme-alpine">
        <AgGridReact
          domLayout="autoHeight"
          gridOptions={gridOptions}
          modules={[
            ClientSideRowModelModule,
            RichSelectModule,
            ExcelExportModule,
          ]}
          onFirstDataRendered={onFirstDataRendered}
          ref={gridRef}
          rowClass="c-grid-row"
          rowData={data}
        />
      </Page.Section.Content>
    </>
  )
})

ZeroProof.propTypes = {
  data: PropTypes.array.isRequired,
}

ZeroProof.displayName = 'ZeroProof'

export default ZeroProof
