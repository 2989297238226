import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { Tenant } from '../../models'
import { formatDate } from '../../utils/format'
import {
  Form,
  FormField,
  FormFieldGrid,
  FormLabel,
  FormSection,
  FormValue,
} from '../core/Form/Form'
import Number from '../core/Number/Number'
import AgreementList from './AgrementList'

interface TenantDetailProps {
  tenant: Tenant
}

export const TenantDetail = ({ tenant }: TenantDetailProps) => {
  const { t } = useTranslation(['dashboard'])

  // FIXME: Return from API
  const currentAgreement = useMemo(() => tenant.agreements[0], [tenant])

  return (
    <Form>
      <FormSection
        title={t('tenantDetail.general')}
        subtitle={t('tenantDetail.generalDescription')}
      >
        <FormFieldGrid>
          <FormField>
            <FormLabel>{t('tenantDetail.building')}</FormLabel>
            <FormValue>{tenant.building.buildingReference}</FormValue>
          </FormField>
          <FormField>
            <FormLabel>{t('tenantDetail.startDate')}</FormLabel>
            <FormValue>{formatDate(currentAgreement?.startDate)}</FormValue>
          </FormField>

          <FormField>
            <FormLabel>{t('tenantDetail.rentReviewDate')}</FormLabel>
            <FormValue>
              {formatDate(currentAgreement?.rentReviewDate)}
            </FormValue>
          </FormField>
          <FormField>
            <FormLabel>{t('tenantDetail.rentAmount')}</FormLabel>
            <FormValue>
              <Number value={currentAgreement?.rentAmount} />
            </FormValue>
          </FormField>
        </FormFieldGrid>
      </FormSection>
      <FormSection
        title={t('tenantDetail.agreements')}
        subtitle={t('tenantDetail.agreementsDescription')}
      >
        <AgreementList tenant={tenant} />
      </FormSection>
    </Form>
  )
}
