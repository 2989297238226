import { Anomaly } from '../models'
import httpClient from '../utils/httpClient'
import { fromJsonApi } from '../utils/jsonapi'

export const getCount = async () => {
  const response = await httpClient.get('/anomaly/count')
  const { count } = response.data

  return count
}

export const getAnomalies = async () => {
  const response = await httpClient.get('/anomaly', {
    params: {
      include: 'vendorInvoice,vendorInvoice.vendor,vendorInvoice.document',
    },
  })

  return fromJsonApi<Anomaly[]>(response.data)
}

export const deleteAnomaly = async (id: number) => {
  await httpClient.delete(`/anomaly/${id}`)
}
