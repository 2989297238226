import { useMutation, useQuery } from '@tanstack/react-query'
import { useCallback, useState } from 'react'
import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'

import Card from '../components/Card/Card'
import Checked from '../components/Checked'
import Page from '../components/Page/LegacyPage'
import Spinner from '../components/Spinner'
import Button from '../components/core/Button/Button'
import Input from '../components/core/Input/Input'
import InputField from '../components/core/Input/InputField'
import Label from '../components/core/Input/Label'
import {
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
} from '../components/core/Listbox'
import useAuth from '../hooks/useAuth'
import { createUser, deleteUser, getUser, updateUser } from '../loaders/user'

const fields = [
  {
    id: 'firstName',
    type: 'text',
    required: true,
    default: {
      value: '',
      label: '',
    },
  },
  {
    id: 'lastName',
    type: 'text',
    required: true,
    default: {
      value: '',
      label: '',
    },
  },
  {
    id: 'email',
    type: 'email',
    required: true,
    default: {
      value: '',
      label: '',
    },
  },
  {
    id: 'role',
    type: 'option',
    default: { value: 'regular', label: 'Regular' },
    required: true,
    options: [
      { value: 'regular', label: 'Regular' },
      { value: 'admin', label: 'Admin' },
    ],
  },
]

const resolveState = (user) => {
  return fields.reduce(
    (acc, curr) => ({
      ...acc,
      [curr.id]: user ? user[curr.id] : curr.default.value,
    }),
    {}
  )
}

const EditUser = () => {
  const auth = useAuth()
  const params = useParams()

  const userId = params.userId ? parseInt(params.userId) : null
  const isSelf = userId === auth.user.userId

  const { t } = useTranslation(['dashboard', 'translation'])
  const tPrefix = userId ? 'editUser' : 'createUser'

  const navigate = useNavigate()
  const [state, setState] = useState(resolveState())

  const { data: user, isLoading } = useQuery({
    enabled: !!userId,
    queryKey: ['user', userId],
    queryFn: () => getUser(userId),
    onError: (error) => {
      toast.error(
        t('user.notification.error', {
          error: error.toString(),
        })
      )
    },
    onSuccess: (user) => {
      setState(resolveState(user))
    },
  })

  const createMutation = useMutation({
    enabled: !user,
    mutationFn: createUser,
  })
  const updateMutation = useMutation({
    enabled: !!user,
    mutationFn: (data) => updateUser(user.userId, data),
  })
  const deleteMutation = useMutation({
    enabled: !!user,
    mutationFn: () => deleteUser(user.userId),
  })

  const onSubmit = useCallback(
    async (event) => {
      event.preventDefault()

      const data = state

      if (userId) {
        updateMutation.mutate(data, {
          onSuccess: () => {
            toast.success(t(`editUser.notification.update.success`))
            navigate('/admin/users')
          },
          onError: (error) => {
            toast.error(
              t(`editUser.notification.update.error`, {
                error: error.toString(),
              })
            )
          },
        })
      } else {
        createMutation.mutate(data, {
          onSuccess: () => {
            toast.success(t(`createUser.notification.success`))
            navigate('/admin/users')
          },
          onError: (error) => {
            toast.error(
              t(`createUser.notification.error`, {
                error: error.toString(),
              })
            )
          },
        })
      }
    },
    [state, user]
  )

  const onDelete = useCallback(async () => {
    deleteMutation.mutate(undefined, {
      onSuccess: () => {
        toast.success(t(`editUser.notification.delete.success`))
        navigate('/admin/users')
      },
      onError: (error) => {
        toast.error(
          t(`editUser.notification.delete.error`, {
            error: error.toString(),
          })
        )
      },
    })
  }, [user])

  if (userId && isLoading) {
    return (
      <Page.Section>
        <Page.Section.Content className="flex justify-center">
          <Spinner />
        </Page.Section.Content>
      </Page.Section>
    )
  }

  return (
    <Checked right="user:admin">
      <Page.Header
        actions={
          user && !isSelf ? (
            <>
              <Button variant="negative" onClick={onDelete}>
                {t(`${tPrefix}.button.delete`)}
              </Button>
            </>
          ) : null
        }
      >
        {t(`${tPrefix}.title`)}
      </Page.Header>
      <Page.Section>
        <Card>
          <form
            onSubmit={onSubmit}
            className="p-6 space-y-8 divide-y divide-gray-200"
          >
            <div className="space-y-8 divide-y divide-gray-200">
              <div>
                <div>
                  <h3 className="text-lg font-medium leading-6 text-gray-900">
                    {t(`${tPrefix}.form.title`)}
                  </h3>
                  <p className="mt-1 text-sm text-gray-500">
                    {t(`${tPrefix}.form.subtitle`)}
                  </p>
                </div>

                <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                  {fields.map((field) => {
                    switch (field.type) {
                      case 'option': {
                        return (
                          <div key={field.id} className="sm:col-span-4">
                            <Listbox
                              value={state[field.id]}
                              required={field.required}
                              onChange={(value) =>
                                setState((prev) => ({
                                  ...prev,
                                  [field.id]: value,
                                }))
                              }
                            >
                              {({ open }) => (
                                <>
                                  <Label className="mb-2">
                                    {t(`editUser.field.${field.id}.title`)}
                                  </Label>
                                  <div className="relative">
                                    <ListboxButton>
                                      {
                                        field.options.find(
                                          ({ value }) =>
                                            value === state[field.id]
                                        ).label
                                      }
                                    </ListboxButton>
                                    <ListboxOptions isOpen={open}>
                                      {field.options.map((option) => (
                                        <ListboxOption
                                          key={option.value}
                                          value={option.value}
                                        >
                                          {option.label}
                                        </ListboxOption>
                                      ))}
                                    </ListboxOptions>
                                  </div>
                                </>
                              )}
                            </Listbox>
                          </div>
                        )
                      }
                      default: {
                        return (
                          <div key={field.id} className="sm:col-span-4">
                            <InputField id={field.id}>
                              <Label className="mb-2">
                                {t(`editUser.field.${field.id}.title`)}
                              </Label>
                              <Input
                                type={field.type}
                                name={field.id}
                                value={state[field.id]}
                                required={field.required}
                                onChange={(event) =>
                                  setState((prev) => ({
                                    ...prev,
                                    [field.id]: event.target.value,
                                  }))
                                }
                              />
                              <p className="mt-2 text-sm text-gray-500">
                                {t(`editUser.field.${field.id}.description`)}
                              </p>
                            </InputField>
                          </div>
                        )
                      }
                    }
                  })}
                </div>
              </div>
            </div>

            <div className="pt-5">
              <div className="flex justify-end">
                <Button
                  variant="neutral"
                  onClick={() => navigate('/admin/users')}
                >
                  {t('form.cancel', { ns: 'translation' })}
                </Button>
                <Button className="ml-3" type="submit" variant="primary">
                  {userId
                    ? t('form.save', { ns: 'translation' })
                    : t('form.submit', { ns: 'translation' })}
                </Button>
              </div>
            </div>
          </form>
        </Card>
      </Page.Section>
    </Checked>
  )
}

export default EditUser
