import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import HttpBackend from 'i18next-http-backend'
import { initReactI18next } from 'react-i18next'

import enTranslation from './locales/en/translation.json'

export const SUPPORTED_LOCALES = ['en', 'da']
export const DEFAULT_LOCALE = 'en'
export const DEFAULT_NS = 'translation'
const DEBUG = process.env.NODE_ENV === 'development'

async function loadLocale(options, url, payload, callback) {
  try {
    const response = await import(`./locales/${url}.json`)
    return callback(null, { data: response.default, status: 200 })
  } catch (err) {
    return callback(err, null)
  }
}

i18n
  .use(LanguageDetector)
  .use(HttpBackend)
  .use(initReactI18next)
  .init({
    backend: {
      allowMultiLoading: false,
      loadPath: (locales, namespaces) => {
        return locales.flatMap((locale) =>
          namespaces.map((ns) => `${locale}/${ns}`)
        )[0]
      },
      request: loadLocale,
    },
    fallbackLng: DEFAULT_LOCALE,
    supportedLngs: SUPPORTED_LOCALES,
    debug: DEBUG,
    ns: DEFAULT_NS,
    returnNull: false,

    interpolation: {
      escapeValue: false,
    },
  })

// preload english common translations
i18n.addResourceBundle('en', 'translation', enTranslation)

export default i18n
