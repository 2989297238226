import { useQuery } from '@tanstack/react-query'
import PropTypes from 'prop-types'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { getBuildingsLegacy } from '../../loaders/buildings'
import {
  Listbox,
  ListboxButton,
  ListboxLabel,
  ListboxOption,
  ListboxOptions,
} from '../core/Listbox'

const useBuildings = (options) => {
  const { data: buildings, isLoading } = useQuery({
    queryKey: ['buildings'],
    queryFn: getBuildingsLegacy,
    staleTime: 2000,
    ...options,
  })

  return {
    buildings,
    isLoading,
  }
}

export default function BuildingSelector({
  selected,
  onChange,
  allowMissing = false,
}) {
  const { t } = useTranslation(['dashboard'])

  const { buildings, isLoading } = useBuildings({
    onSuccess: (data) => {
      if (allowMissing) {
        const missingBuilding = {
          buildingId: null,
          buildingReference: 'Missing',
        }

        data.unshift(missingBuilding)
      }

      if (!selected) {
        onChange(data[0])
      }
    },
  })

  const selectedBuilding = useMemo(() => {
    if (!buildings || !selected) {
      return null
    }

    return buildings.find(
      (building) => building.buildingId === selected.buildingId
    )
  }, [selected])

  if (isLoading || !selectedBuilding) {
    return null
  }

  return (
    <Listbox
      value={selectedBuilding.buildingId}
      onChange={(option) => onChange(option)}
    >
      {({ open }) => (
        <>
          <ListboxLabel>{t('buildings.selector.label')}</ListboxLabel>
          <div className="relative mt-1">
            <ListboxButton>{selectedBuilding.buildingReference}</ListboxButton>
            <ListboxOptions isOpen={open}>
              {buildings.map((option) => (
                <ListboxOption key={option.buildingId} value={option}>
                  {option.buildingReference}
                </ListboxOption>
              ))}
            </ListboxOptions>
          </div>
        </>
      )}
    </Listbox>
  )
}

BuildingSelector.propTypes = {
  selected: PropTypes.object,
  allowMissing: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
}
