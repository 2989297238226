import PropTypes from 'prop-types'
import { Fragment, useCallback, useRef } from 'react'
import { useTranslation } from 'react-i18next'

import Button from '../core/Button/Button'

const UploadData = ({ onUpload }) => {
  const { t } = useTranslation()
  const inputRef = useRef(null)

  const onClick = useCallback((event) => {
    event.preventDefault()

    if (inputRef.current) {
      inputRef.current.click()
    }
  })

  return (
    <Fragment>
      <input
        ref={inputRef}
        onChange={onUpload}
        id="uploadInput"
        type="file"
        className="sr-only"
        accept=".xlsx, .xls, .csv"
        tabIndex={-1}
      />
      <Button onClick={onClick} className="ml-2">
        {t('upload.label')}
      </Button>
    </Fragment>
  )
}

UploadData.propTypes = {
  onUpload: PropTypes.func.isRequired,
}

export default UploadData
