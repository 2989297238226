import { getGridValueFormatter } from '../../../utils/format'

export default (numberOptions) => ({
  headerName: 'Value',
  field: 'value',
  width: 150,
  resizable: true,
  cellClass: ['c-grid-cell', 'c-grid-cell--numeric'],
  valueFormatter: getGridValueFormatter('value', numberOptions),
})
