const absolutBudgetDiffPercentageWeights = [
  {
    from: 0,
    to: 0.1,
    weight: 0,
  },
  {
    from: 0.1,
    to: 0.25,
    weight: 0.2,
  },
  {
    from: 0.25,
    to: 0.5,
    weight: 0.4,
  },
  {
    from: 0.5,
    to: 1,
    weight: 0.6,
  },
  {
    from: 1,
    to: 2,
    weight: 0.8,
  },
  {
    from: 2,
    weight: 1,
  },
]

export const getWeight = (absoluteBudgetDiffPercentage) => {
  if (absoluteBudgetDiffPercentage === 0) {
    return 0
  }

  const absolutBudgetDiffWeight = absolutBudgetDiffPercentageWeights.find(
    (absolutBudgetDiffWeight) => {
      const { from, to } = absolutBudgetDiffWeight

      const isGreaterThanFrom = absoluteBudgetDiffPercentage >= from
      if (to === undefined) {
        return isGreaterThanFrom
      }

      const isSmallerThanTo = absoluteBudgetDiffPercentage < to
      if (to === undefined) {
        return isSmallerThanTo
      }

      return isGreaterThanFrom && isSmallerThanTo
    }
  )

  return absolutBudgetDiffWeight.weight
}

const getBudgetDiff = (actual, budget) => {
  let budgetDiffPercentage
  if (actual != null && budget != null) {
    // round to 2 decimals
    budgetDiffPercentage = +(actual - budget).toFixed(2)
  }

  return budgetDiffPercentage
}

export const getNeutralColor = () => {
  return `var(--tw-color-neutral-200)`
}

export const getPositiveColor = (weight) => {
  return `var(--tw-color-positive-${weight * 1000})`
}

export const getNegativeColor = (weight) => {
  return `var(--tw-color-negative-${weight * 1000})`
}

export const getResultColor = (data) => {
  const { category, actual, budget } = data

  if (!budget) {
    return getNeutralColor()
  }

  const budgetDiff = getBudgetDiff(actual, budget)

  if (!budgetDiff) {
    return getNeutralColor()
  }

  const aboveBudget = budgetDiff > 0
  const belowBudget = budgetDiff < 0

  const absoluteBudgetDiffPercentage = Math.abs(budgetDiff / budget)
  const weight = getWeight(absoluteBudgetDiffPercentage)

  // If we are looking at expenses then above budget is bad
  if (category && category.type === 'Expense') {
    return aboveBudget
      ? getNegativeColor(weight)
      : belowBudget
        ? getPositiveColor(weight)
        : getNeutralColor()
  } else {
    // All other cases above budget is good
    return aboveBudget
      ? getPositiveColor(weight)
      : belowBudget
        ? getNegativeColor(weight)
        : getNeutralColor()
  }
}
