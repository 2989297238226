import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react'
import {
  ChevronDownIcon,
  ChevronUpIcon,
  PowerIcon,
  UserIcon,
} from '@heroicons/react/20/solid'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import useAuth from '../../hooks/useAuth'
import { Avatar } from '../Avatar'

const links = [
  {
    to: '/profile',
    label: 'navbar.menu.profile',
    icon: UserIcon,
  },
  {
    to: '/logout',
    label: 'navbar.menu.logout',
    icon: PowerIcon,
  },
]

const ProfileMenu = () => {
  const { t } = useTranslation()
  const auth = useAuth()

  const name = useMemo(() => {
    return auth.user ? `${auth.user.firstName} ${auth.user.lastName}` : ''
  }, [auth])

  return (
    <Menu
      as="div"
      className="text-sm font-semibold leading-6 text-gray-900 hover:bg-gray-50"
    >
      <div>
        <MenuButton className="w-full flex px-3 lg:px-6 py-3 gap-x-3 items-center justify-between">
          <div className="flex items-center gap-x-4">
            <Avatar />
            <span aria-hidden="true" className="hidden lg:inline">
              {name}
            </span>
          </div>
          <ChevronUpIcon
            aria-hidden="true"
            className="hidden lg:block h-5 w-5 text-gray-400"
          />
          <ChevronDownIcon
            aria-hidden="true"
            className="lg:hidden h-5 w-5 text-gray-400"
          />
        </MenuButton>
      </div>

      <MenuItems
        transition
        anchor="top"
        className="z-50 w-56 origin-bottom divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
      >
        {links.map((link) => (
          <MenuItem key={link.to}>
            <Link
              to={link.to}
              className="group flex items-center px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900"
            >
              <link.icon
                aria-hidden="true"
                className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
              />
              {t(link.label)}
            </Link>
          </MenuItem>
        ))}
      </MenuItems>
    </Menu>
  )
}

export default ProfileMenu
