import { useTranslation } from 'react-i18next'

import backgroundImage from '../assets/img/river-6175173.jpg'
import LoginForm from '../components/LoginForm/LoginForm'
import Toaster from '../components/ToastProvider'
import * as Typography from '../components/core/Typography'

const LoginPage = () => {
  const { t } = useTranslation(['login'])

  return (
    <>
      <div className="flex min-h-full">
        <div className="flex flex-1 flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24 bg-white">
          <div className="mx-auto w-full max-w-sm lg:w-96">
            <div>
              <Typography.H2 className="mt-6">{t('title')}</Typography.H2>
            </div>
            <div className="mt-8">
              <div className="mt-6">
                <LoginForm />
              </div>
            </div>
          </div>
        </div>
        <div className="relative hidden w-0 flex-1 lg:block">
          <div
            className="absolute inset-0 h-full w-full bg-cover"
            style={{ backgroundImage: `url(${backgroundImage})` }}
          />
        </div>
      </div>
      <Toaster />
    </>
  )
}

export default LoginPage
