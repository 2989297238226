import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model'
import { useQuery } from '@tanstack/react-query'
import _ from 'lodash'
import { useCallback, useReducer, useRef } from 'react'
import { useTranslation } from 'react-i18next'

import AbsoluteSwitch from '../components/AbsoluteSwitch/AbsoluteSwitch'
import AnnualizeSwitch from '../components/AnnualizeSwitch/AnnualizeSwitch'
import ByIntervalSelector from '../components/ByIntervalSelector/ByIntervalSelector'
import CategorySelector from '../components/CategorySelector/CategorySelector'
import Checked from '../components/Checked'
import AgGridReact from '../components/Grid/Grid'
import NoiDefinitionSelector from '../components/NoiDefinitionSelector/NoiDefinitionSelector'
import Page from '../components/Page/LegacyPage'
import PortfolioSelector from '../components/PortfolioSelector/PortfolioSelector'
import {
  AccountName,
  AccountNumber,
  Amount,
  ProcessingDate,
  RecordId,
  Text,
} from '../components/Records/columns'
import Spinner from '../components/Spinner'
import BuildingsGrid from '../components/TimelinesDrilldown/BuildingsGrid/BuildingsGrid'
import ChildCategoriesLegend from '../components/TimelinesDrilldown/LineChart/Legend'
import LineChart from '../components/TimelinesDrilldown/LineChart/LineChart'
import {
  ChildCategoriesTooltip,
  EmptyTooltip,
} from '../components/TimelinesDrilldown/LineChart/Tooltip'
import {
  getBuildingsTimelinesStyler,
  getChildCategoriesStyler,
} from '../components/TimelinesDrilldown/LineChart/styling'
import Stories from '../components/TimelinesDrilldown/Stories'
import TimelinesGrid from '../components/TimelinesDrilldown/TimelinesGrid/TimelinesGrid'
import TimelinesTabSelector from '../components/TimelinesTabSelector/TimelinesTabSelector'
import Button from '../components/core/Button/Button'
import { getBuildingCategoryNoiDefinitionRecords } from '../loaders/records'
import {
  getAnomaliesStories,
  getChildCategoriesTimelines,
  getTimelines,
} from '../loaders/result'

const initialState = {
  portfolio: null,
  building: null,
  category: null,
  annualize: false,
  absolute: false,
  noiDefinition: null,
  byInterval: 'month',
  year: null,
  interval: null,
  yearIntervalData: [],
  showRecords: false,
  showAnomaliesStories: false,
  anomaliesStories: null,
  tab: 'linesChart',
}

const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_NOI_DEFINITION': {
      if (state.noiDefinition === action.data) {
        return state
      }

      return {
        ...state,
        noiDefinition: action.data,
        showRecords: false,
        showAnomaliesStories: false,
        anomaliesStories: null,
      }
    }

    case 'SET_PORTFOLIO': {
      if (state.portfolio === action.data) {
        return state
      }

      return {
        ...state,
        portfolio: action.data,
        showRecords: false,
        showAnomaliesStories: false,
        anomaliesStories: null,
      }
    }

    case 'SET_CATEGORY': {
      if (state.category === action.data) {
        return state
      }

      return {
        ...state,
        category: action.data,
        showRecords: false,
        showAnomaliesStories: false,
        anomaliesStories: null,
      }
    }

    case 'SET_ANNUALIZE': {
      const annualize = action.data

      return {
        ...state,
        annualize,
        building: null,
        year: null,
        yearIntervalData: [],
        showRecords: false,
        showAnomaliesStories: false,
        anomaliesStories: null,
      }
    }

    case 'SET_ABSOLUTE': {
      const absolute = action.data

      return {
        ...state,
        absolute,
        building: null,
        year: null,
        yearIntervalData: [],
        showRecords: false,
        showAnomaliesStories: false,
        anomaliesStories: null,
      }
    }

    case 'SET_BY_INTERVAL': {
      const byInterval = action.data

      return {
        ...state,
        building: null,
        year: null,
        byInterval,
        yearIntervalData: [],
        showRecords: false,
        showAnomaliesStories: false,
        anomaliesStories: null,
      }
    }

    case 'SET_BUILDING_YEAR_INTERVAL_DATA': {
      if (state.yearIntervalData === action.data) {
        return state
      }

      const { year, interval, building } = action.data[0].payload

      const yearIntervalData = action.data.filter(
        (buildingYearIntervalData) => !buildingYearIntervalData.payload.isBudget
      )

      return {
        ...state,
        building,
        year,
        interval,
        yearIntervalData,
        showRecords: false,
      }
    }

    case 'SET_YEAR_INTERVAL': {
      const { year, interval } = action.data

      return { ...state, year, interval, showRecords: false }
    }

    case 'SET_BUILDING': {
      const building = action.data

      return { ...state, building, showRecords: false }
    }

    case 'SET_SHOW_RECORDS': {
      const showRecords = action.data

      return { ...state, showRecords }
    }

    case 'SET_SHOW_ANOMALIES_STORIES': {
      const showAnomaliesStories = action.data

      return { ...state, showAnomaliesStories }
    }

    case 'SET_ANOMALIES_STORIES': {
      const anomaliesStories = action.data

      return { ...state, anomaliesStories }
    }

    case 'SET_TAB': {
      const tab = action.data

      return { ...state, tab }
    }

    default:
      return state
  }
}

export default function TimelinesDrilldown() {
  const { t } = useTranslation(['dashboard', 'translation'])
  const [
    {
      portfolio,
      building,
      category,
      annualize,
      absolute,
      noiDefinition,
      year,
      interval,
      byInterval,
      yearIntervalData,
      showRecords,
      showAnomaliesStories,
      anomaliesStories,
      tab,
    },
    dispatch,
  ] = useReducer(reducer, initialState)

  const buildingsTimelinesGridRef = useRef()
  const onDownloadBuildingsTimelines = useCallback(() => {
    buildingsTimelinesGridRef.current.api.exportDataAsExcel()
  })

  const childCategoriesTimelinesGridRef = useRef()
  const onDownloadChildcategoriesTimelines = useCallback(() => {
    childCategoriesTimelinesGridRef.current.api.exportDataAsExcel()
  })

  const portfolioId = portfolio?.portfolioId
  const categoryId = category?.categoryId
  const buildingId = building?.buildingId
  const noiDefinitionId = noiDefinition?.noiDefinitionId

  const { isLoading: isTimelinesLoading, data: timelinesData } = useQuery({
    enabled: !!portfolioId && !!categoryId && !!noiDefinitionId,
    queryKey: [
      'timelines',
      portfolioId,
      categoryId,
      noiDefinitionId,
      annualize,
      absolute,
      byInterval,
    ],
    queryFn: () =>
      getTimelines(portfolioId, categoryId, noiDefinitionId, {
        annualize,
        absolute,
        byInterval,
      }),
    staleTime: Infinity,
  })

  const onChangeNoiDefinition = useCallback(
    (noiDefinition) =>
      dispatch({ type: 'SET_NOI_DEFINITION', data: noiDefinition }),
    [dispatch]
  )

  const onChangePortfolio = useCallback(
    (portfolio) => dispatch({ type: 'SET_PORTFOLIO', data: portfolio }),
    [dispatch]
  )

  const onChangeCategory = useCallback(
    (category) => dispatch({ type: 'SET_CATEGORY', data: category }),
    [dispatch]
  )

  const onChangeAnnualize = useCallback(
    (annualize) => dispatch({ type: 'SET_ANNUALIZE', data: annualize }),
    [dispatch]
  )

  const onChangeAbsolute = useCallback(
    (absolute) => dispatch({ type: 'SET_ABSOLUTE', data: absolute }),
    [dispatch]
  )

  const onChangeByInterval = useCallback(
    (byInterval) => dispatch({ type: 'SET_BY_INTERVAL', data: byInterval }),
    [dispatch]
  )

  const onChangeTab = useCallback(
    (tab) => dispatch({ type: 'SET_TAB', data: tab }),
    [dispatch]
  )

  const onBuildingsGridClick = useCallback(
    ({
      data: {
        payload: { building },
      },
    }) =>
      dispatch({
        type: 'SET_BUILDING',
        data: building,
      }),
    [dispatch]
  )

  const onTimelinesGridClick = useCallback(
    (params) => {
      const {
        column: { colId },
        data: { building },
      } = params

      dispatch({
        type: 'SET_BUILDING',
        data: building,
      })

      let year = null
      let interval = null
      if (colId !== 'name') {
        ;[year, interval] = colId.split('-')

        year = parseInt(year)
        interval = parseInt(interval)
      }

      dispatch({
        type: 'SET_YEAR_INTERVAL',
        data: {
          year,
          interval,
        },
      })
    },
    [dispatch]
  )

  const onChartClick = useCallback(
    (params) => {
      const orderedYearIntervalData = _.orderBy(
        params.activePayload,
        ({ value }) => value || '',
        'desc'
      )

      dispatch({
        type: 'SET_BUILDING_YEAR_INTERVAL_DATA',
        data: orderedYearIntervalData,
      })
    },
    [dispatch]
  )

  const onClickShowRecordsButton = useCallback(
    () =>
      dispatch({
        type: 'SET_SHOW_RECORDS',
        data: true,
      }),
    [dispatch]
  )

  const { data: childCategoriesTimelines } = useQuery({
    enabled: !!buildingId && !!categoryId && !!noiDefinitionId,
    queryKey: [
      'buildingChildCategoriesTimelines',
      buildingId,
      categoryId,
      noiDefinitionId,
      annualize,
      absolute,
      byInterval,
    ],
    queryFn: () =>
      getChildCategoriesTimelines(buildingId, categoryId, noiDefinitionId, {
        annualize,
        absolute,
        byInterval,
      }),
    staleTime: Infinity,
  })

  const { data: records } = useQuery({
    enabled:
      !!buildingId &&
      !!categoryId &&
      !!noiDefinitionId &&
      !!year &&
      !!interval &&
      showRecords,
    queryKey: [
      'buildingCategoryRecords',
      buildingId,
      categoryId,
      noiDefinitionId,
      year,
      interval,
    ],
    queryFn: () =>
      getBuildingCategoryNoiDefinitionRecords(
        buildingId,
        categoryId,
        noiDefinitionId,
        {
          year,
          interval,
          byInterval,
        }
      ),
    staleTime: Infinity,
  })

  const onClickShowAnomaliesStoriesButton = useCallback(
    () =>
      dispatch({
        type: 'SET_SHOW_ANOMALIES_STORIES',
        data: true,
      }),
    [dispatch]
  )

  const { isLoading: isAnomaliesStoriesLoading } = useQuery({
    enabled:
      !!portfolioId &&
      !!categoryId &&
      !!noiDefinitionId &&
      showAnomaliesStories,
    queryKey: ['anomaliesStories', portfolioId, categoryId, noiDefinitionId],
    queryFn: () =>
      getAnomaliesStories(portfolioId, categoryId, noiDefinitionId),
    staleTime: Infinity,
    onSuccess: (data) => {
      dispatch({
        type: 'SET_ANOMALIES_STORIES',
        data,
      })
    },
  })

  const getBuildingsTimelinesStyling = getBuildingsTimelinesStyler(buildingId)
  const getChildCategoriesStyling = getChildCategoriesStyler(categoryId)

  let isEmpty = !isTimelinesLoading && !timelinesData

  let timelines = []
  if (timelinesData) {
    for (const timeline of timelinesData.timelines) {
      // Show all building timelines for actual
      // Show all non-building-specific timelines, like 'Trend' and 'Average'
      // Only show timeline for budget associated with a building that has been clicked
      if (
        !timeline.building ||
        !timeline.isBudget ||
        timeline.building.buildingId === buildingId
      ) {
        timelines.push(timeline)
      }
    }
  }

  const yAxisLabelValue = absolute
    ? t('timelinesDrilldown.timelineLines.yAxisLabelAbsolute')
    : t('timelinesDrilldown.timelineLines.yAxisLabelPerSquareMeter')

  const xAxisLabelValue =
    byInterval === 'month'
      ? t('timelinesDrilldown.timelineLines.xAxisLabel.month')
      : t('timelinesDrilldown.timelineLines.xAxisLabel.quarter')

  return (
    <Checked right="result:read">
      <Page>
        <Page.Header>{t('timelinesDrilldown.title')}</Page.Header>
        <Page.Section id="noiDefinition">
          <Page.Section.Content>
            <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
              <div key="noiDefinition" className="sm:col-span-4">
                <NoiDefinitionSelector
                  selected={noiDefinition}
                  onChange={onChangeNoiDefinition}
                />
              </div>
            </div>
          </Page.Section.Content>
        </Page.Section>

        <Page.Section id="portfolio">
          <Page.Section.Content>
            <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
              <div key="portfolio" className="sm:col-span-4">
                <PortfolioSelector
                  selected={portfolio}
                  onChange={onChangePortfolio}
                />
              </div>
            </div>
          </Page.Section.Content>
        </Page.Section>

        <Page.Section id="category">
          <Page.Section.Content>
            <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
              <div key="category" className="sm:col-span-4">
                <CategorySelector
                  selected={category}
                  onChange={onChangeCategory}
                  topCategoryStandard="root__operating"
                  includeTopCategory={true}
                />
              </div>
            </div>
          </Page.Section.Content>
        </Page.Section>

        <Page.Section id="switches">
          <Page.Section.Content>
            <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
              <div key="annualize" className="sm:col-span-4">
                <AnnualizeSwitch
                  enabled={annualize}
                  onChange={onChangeAnnualize}
                />
              </div>
            </div>
            <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
              <div key="annualize" className="sm:col-span-4">
                <AbsoluteSwitch
                  enabled={absolute}
                  onChange={onChangeAbsolute}
                />
              </div>
            </div>
          </Page.Section.Content>
        </Page.Section>

        <Page.Section id="interval">
          <Page.Section.Content>
            <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
              <div key="interval" className="sm:col-span-4">
                <ByIntervalSelector
                  selected={byInterval}
                  onChange={onChangeByInterval}
                />
              </div>
            </div>
          </Page.Section.Content>
        </Page.Section>

        {isTimelinesLoading ? (
          <Page.Section className="text-center justify-center">
            <Spinner isFull />
          </Page.Section>
        ) : isEmpty ? (
          <Page.Section>
            <div>{t('timelinesDrilldown.empty')}</div>
          </Page.Section>
        ) : (
          <>
            <Page.Section>
              <Page.Section.Title>
                {t('timelinesDrilldown.trendStories.title')}
              </Page.Section.Title>
              <Page.Section.Content>
                <Stories stories={timelinesData.trendStories} />
              </Page.Section.Content>
            </Page.Section>
            <Page.Section>
              <Page.Section.Title>
                {t('timelinesDrilldown.anomalies.title')}
              </Page.Section.Title>
              <Page.Section.Content>
                <Button onClick={onClickShowAnomaliesStoriesButton}>
                  {t('timelinesDrilldown.anomalies.showStories')}
                </Button>{' '}
              </Page.Section.Content>
              <Page.Section.Content>
                {showAnomaliesStories && isAnomaliesStoriesLoading ? (
                  <div className="flex justify-center">
                    <Spinner />
                  </div>
                ) : (
                  showAnomaliesStories &&
                  anomaliesStories && <Stories stories={anomaliesStories} />
                )}
              </Page.Section.Content>
            </Page.Section>
            <Page.Section>
              <Page.Section.Title>
                {t('timelinesDrilldown.timelineLines.title', {
                  categoryName: category.paddedName,
                })}
              </Page.Section.Title>
              <Page.Section.Content>
                <TimelinesTabSelector selected={tab} onChange={onChangeTab} />
              </Page.Section.Content>
              {tab === 'linesChart' ? (
                <Page.Section.Content>
                  <div className="mt-6 grid grid-cols-2 gap-y-6 gap-x-4 sm:grid-cols-6">
                    <div className="sm:col-span-4">
                      <LineChart
                        data={timelines}
                        getStyling={getBuildingsTimelinesStyling}
                        tooltip={EmptyTooltip}
                        onClick={onChartClick}
                        yAxisLabelValue={yAxisLabelValue}
                        xAxisLabelValue={xAxisLabelValue}
                      ></LineChart>
                    </div>
                    <div className="sm:col-span-2">
                      {yearIntervalData.length ? (
                        <>
                          {t('timelinesDrilldown.yearIntervalData.title', {
                            year,
                            interval,
                          })}
                          <BuildingsGrid
                            yearIntervalData={yearIntervalData}
                            onBuildingsGridClick={onBuildingsGridClick}
                            getStyling={getBuildingsTimelinesStyling}
                            building={building}
                          />
                        </>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                </Page.Section.Content>
              ) : (
                <>
                  <Page.Section.Content>
                    <Button onClick={onDownloadBuildingsTimelines}>
                      {t('timelinesDrilldown.export')}
                    </Button>
                  </Page.Section.Content>

                  <Page.Section.Content className="ag-theme-alpine">
                    <TimelinesGrid
                      timelines={timelines}
                      onTimelinesGridClick={onTimelinesGridClick}
                      getStyling={getBuildingsTimelinesStyling}
                      ref={buildingsTimelinesGridRef}
                    />
                  </Page.Section.Content>
                </>
              )}
              {building && childCategoriesTimelines && (
                <>
                  <Page.Section.Title>
                    {t('timelinesDrilldown.buildingReference', {
                      buildingReference: building.buildingReference,
                    })}
                  </Page.Section.Title>
                  {tab === 'linesChart' ? (
                    <Page.Section.Content>
                      <div className="mt-6 grid grid-cols-2 gap-y-6 gap-x-4 sm:grid-cols-6">
                        <div className="sm:col-span-4">
                          <LineChart
                            data={childCategoriesTimelines}
                            tooltip={ChildCategoriesTooltip}
                            getStyling={getChildCategoriesStyling}
                            legend={ChildCategoriesLegend}
                            yAxisLabelValue={yAxisLabelValue}
                            xAxisLabelValue={xAxisLabelValue}
                          ></LineChart>
                        </div>
                      </div>
                    </Page.Section.Content>
                  ) : (
                    <>
                      <Page.Section.Content>
                        <Button onClick={onDownloadChildcategoriesTimelines}>
                          {t('timelinesDrilldown.export')}
                        </Button>
                      </Page.Section.Content>

                      <Page.Section.Content className="ag-theme-alpine">
                        <TimelinesGrid
                          timelines={childCategoriesTimelines}
                          getStyling={getChildCategoriesStyling}
                          ref={childCategoriesTimelinesGridRef}
                        />
                      </Page.Section.Content>
                    </>
                  )}
                  {year && interval && (
                    <Button onClick={onClickShowRecordsButton}>
                      {t('timelinesDrilldown.showRecords', {
                        year,
                        interval,
                        buildingReference: building.buildingReference,
                      })}
                    </Button>
                  )}
                </>
              )}
            </Page.Section>
            {records && showRecords && (
              <Page.Section id="buildingCategoryRecords">
                <Page.Section.Title>
                  {t('timelinesDrilldown.buildingCategoryRecords.title', {
                    buildingReference: building.buildingReference,
                    year,
                    interval,
                  })}
                </Page.Section.Title>
                <Page.Section.Content className="ag-theme-alpine">
                  <AgGridReact
                    domLayout="autoHeight"
                    gridOptions={{
                      columnDefs: [
                        RecordId,
                        ProcessingDate,
                        AccountName,
                        AccountNumber,
                        Text,
                        Amount,
                      ],
                    }}
                    modules={[ClientSideRowModelModule]}
                    rowClass="c-grid-row"
                    rowData={records}
                  />
                </Page.Section.Content>
              </Page.Section>
            )}
          </>
        )}
      </Page>
    </Checked>
  )
}
