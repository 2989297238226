import axios from 'axios'

const http = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: { 'Context-Type': 'application/json' },
})

http.interceptors.request.use((config) => {
  const token = JSON.parse(localStorage.getItem('token'))

  if (token) {
    config.headers['Authorization'] = `Bearer ${token}`
  }

  return config
})

http.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401 || error.response.status === 403) {
      window.location = '/login'
    }

    return Promise.reject(error)
  }
)

export default http
