import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model'
import { RichSelectModule } from '@ag-grid-enterprise/rich-select'
import PropTypes from 'prop-types'
import { forwardRef, useCallback, useMemo } from 'react'
import useResizeObserver from 'use-resize-observer'

import useNumberOptions from '../../hooks/useNumberOptions'
import AgGridReact from '../Grid/Grid'
import Page from '../Page/LegacyPage'
import { ApplicableDate, BuildingReference, Value } from './columns/'

const getColumnDefs = (numberOptions) => [
  BuildingReference,
  ApplicableDate,
  Value(numberOptions),
]

const Grid = forwardRef(({ metricValues }, gridRef) => {
  useResizeObserver({
    ref: document.body,
    onResize: () => {
      if (gridRef.current.api) {
        gridRef.current.api.sizeColumnsToFit({
          defaultMinWidth: 160,
        })
      }
    },
  })

  const onFirstDataRendered = useCallback(() => {
    if (gridRef.current) {
      gridRef.current.api.sizeColumnsToFit({
        defaultMinWidth: 160,
      })
    }
  }, [])

  const numberOptions = useNumberOptions()

  const gridOptions = useMemo(
    () => ({
      columnDefs: getColumnDefs(numberOptions),
      rowSelection: 'multiple',
    }),
    [numberOptions]
  )

  return (
    <>
      <Page.Section.Content className="ag-theme-alpine">
        <AgGridReact
          domLayout="autoHeight"
          onFirstDataRendered={onFirstDataRendered}
          modules={[ClientSideRowModelModule, RichSelectModule]}
          gridOptions={gridOptions}
          rowData={metricValues}
          ref={gridRef}
        />
      </Page.Section.Content>
    </>
  )
})

Grid.propTypes = {
  metricValues: PropTypes.array.isRequired,
}

Grid.displayName = 'Grid'

export default Grid
