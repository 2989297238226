import { curveCardinal } from 'd3'
import _ from 'lodash'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Area,
  ComposedChart,
  Line,
  ResponsiveContainer,
  Tooltip,
  XAxis,
} from 'recharts'

import useNumberOptions from '../../hooks/useNumberOptions'
import { formatInterval, formatNumber } from '../../utils/format'

export interface Observation {
  interval: number
  xAxisLabel: string
  y: number
  budget: number
  year: number
}

export interface Overview {
  actual: number
  budget: number
  underwriting: number
  budgetDiff?: number
  timelines: {
    actual: Observation[]
    budget: Observation[]
  }
}

interface NoiChartProps {
  observations: Observation[]
  budgetObservations?: Observation[]
}

const LINE_FUNCTION = curveCardinal.tension(0.85)

const COLORS = {
  positive: '#2cbb54',
  negative: '#f87171',
  axis: '#808080',
  budget: '#9ca3af',
}

const gradientOffset = (observations: Observation[]) => {
  const dataMax = Math.max(...observations.map((i) => i.y))
  const dataMin = Math.min(...observations.map((i) => i.y))

  if (dataMax <= 0) {
    return 0
  }
  if (dataMin >= 0) {
    return 1
  }

  return dataMax / (dataMax - dataMin)
}

interface Payload {
  label: string
  actual: number
  budget?: number
}

const mergeObservations = (
  observations: Observation[],
  budgetObservations: Observation[]
): Payload[] => {
  if (!observations) return []

  return _.zip(observations, budgetObservations).map(
    ([observation, budgetObservation]) => {
      return {
        label: formatInterval(
          observation!.year,
          observation!.interval,
          'month'
        ),
        actual: observation!.y,
        budget: budgetObservation?.y,
      }
    }
  )
}

export const ColoredDot = (props: unknown & { color: string }) => {
  const { cx, cy, color } = props as {
    cx: number
    cy: number
    payload: Payload
    color: string
  }

  return (
    <circle
      cx={cx}
      cy={cy}
      r={4}
      style={{ opacity: '0.9' }}
      strokeWidth={0}
      fill={color}
    />
  )
}

export const GradientDot = (props: unknown) => {
  const {
    cx,
    cy,
    payload: { actual },
  } = props as {
    cx: number
    cy: number
    payload: Payload
  }

  return (
    <circle
      cx={cx}
      cy={cy}
      r={4}
      style={{ opacity: '0.9' }}
      strokeWidth={0}
      fill={actual >= 0 ? COLORS.positive : COLORS.negative}
    />
  )
}

const TooltipContent = (props: unknown) => {
  const { t } = useTranslation(['dashboard'])
  const numberOptions = useNumberOptions({
    isCurrency: true,
  })

  const { active, payload } = props as {
    active: boolean
    payload: { payload: Payload }[]
    label: string
  }

  const actual = useMemo(() => {
    return (
      payload &&
      payload.length &&
      formatNumber(payload[0].payload.actual, numberOptions)
    )
  }, [numberOptions, payload])

  const budget = useMemo(() => {
    return (
      payload &&
      payload.length &&
      payload[0].payload.budget &&
      formatNumber(payload[0].payload.budget, numberOptions)
    )
  }, [numberOptions, payload])

  if (active) {
    return (
      <div className="bg-white bg-opacity-90 p-2 text-gray-700 text-sm rounded-md space-y-1">
        <div>
          <span className="font-medium">{t('noiChart.actual')}:</span> {actual}
        </div>
        {budget && (
          <div>
            <span className="font-medium">{t('noiChart.budget')}:</span>{' '}
            {budget}
          </div>
        )}
      </div>
    )
  }
}

const NoiChart = ({ observations, budgetObservations = [] }: NoiChartProps) => {
  const offset = useMemo(
    () => (observations ? gradientOffset(observations) : 0),
    [observations]
  )

  const data = useMemo(
    () => mergeObservations(observations, budgetObservations),
    [observations, budgetObservations]
  )

  if (!observations) return null

  return (
    <ResponsiveContainer width="100%" height="100%">
      <ComposedChart data={data} margin={{ right: 24, left: 24 }}>
        <defs>
          <linearGradient id="colorArea" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor={COLORS.positive} stopOpacity={0.2} />
            <stop offset={offset} stopColor={COLORS.positive} stopOpacity={0} />
            <stop offset={offset} stopColor={COLORS.negative} stopOpacity={0} />
            <stop offset="95%" stopColor={COLORS.negative} stopOpacity={0.2} />
          </linearGradient>
          <linearGradient id="colorLine" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor={COLORS.positive} />
            <stop offset={offset} stopColor={COLORS.positive} />
            <stop offset={offset} stopColor={COLORS.negative} />
            <stop offset="95%" stopColor={COLORS.negative} />
          </linearGradient>
        </defs>
        <XAxis
          dataKey="label"
          stroke={COLORS.axis}
          axisLine={false}
          tickLine={false}
          style={{ fontFamily: 'Inter', fontSize: '12px' }}
        />
        <Tooltip content={<TooltipContent />} />
        <Area
          type={LINE_FUNCTION}
          dataKey="actual"
          stroke="url(#colorLine)"
          strokeWidth={2}
          fillOpacity={1}
          fill="url(#colorArea)"
          activeDot={<GradientDot />}
        />
        {budgetObservations.length && (
          <Line
            type={LINE_FUNCTION}
            dataKey="budget"
            stroke={COLORS.budget}
            strokeWidth={2}
            strokeOpacity={0.5}
            strokeDasharray="5 5"
            strokeLinecap="round"
            dot={false}
            activeDot={<ColoredDot color={COLORS.budget} />}
          />
        )}
      </ComposedChart>
    </ResponsiveContainer>
  )
}

export default NoiChart
