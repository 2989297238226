import { useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { Pagination } from '../../models'
import Button from '../core/Button/Button'

interface PaginationNav {
  pagination: Pagination
  onPrevious: () => void
  onNext: () => void
}

const PaginationNav = ({ pagination, onPrevious, onNext }: PaginationNav) => {
  const { t } = useTranslation(['dashboard'])

  if (!pagination) {
    return null
  }

  const { firstCount, lastCount, totalCount } = useMemo(() => {
    return {
      firstCount: (pagination.page - 1) * pagination.pageSize + 1,
      lastCount: Math.min(
        pagination.page * pagination.pageSize,
        pagination.total
      ),
      totalCount: pagination.total,
    }
  }, [pagination])

  return (
    <nav
      aria-label="Pagination"
      className="flex items-center justify-between border-t border-gray-200 bg-white min-h-14 px-4 py-3 sm:px-6"
    >
      <div className="hidden sm:block">
        <p className="text-sm text-gray-700">
          <Trans
            t={t}
            i18nKey="pagination.showing"
            values={{
              firstCount,
              lastCount,
              totalCount,
            }}
            components={{
              strong: <span className="font-semibold" />,
            }}
          />
        </p>
      </div>
      <div className="flex flex-1 justify-between sm:justify-end">
        {pagination.pageCount > 1 && (
          <>
            <Button onClick={onPrevious} variant="neutral">
              {t('pagination.previous')}
            </Button>
            <Button onClick={onNext} variant="neutral" className="ml-3">
              {t('pagination.next')}
            </Button>
          </>
        )}
      </div>
    </nav>
  )
}

export default PaginationNav
