import httpClient from '../utils/httpClient'

export const getInternalBenchmarks = async (buildingId) => {
  const response = await httpClient.get(
    `/internal_benchmark/building/${buildingId}/`
  )

  return response.data
}

export const bulkDeleteInternalBenchmarks = async (ids) => {
  return Promise.all(
    ids.map((id) => httpClient.delete(`/internal_benchmark/${id}/delete`))
  )
}

export const uploadInternalBenchmarksFile = (buildingId, file) => {
  const formData = new FormData()
  formData.append('file', file)

  return httpClient.post(
    `/internal_benchmark/building/${buildingId}/upload`,
    formData
  )
}
