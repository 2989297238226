import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model'
import { useQuery } from '@tanstack/react-query'
import PropTypes from 'prop-types'
import { useCallback, useReducer } from 'react'
import { useTranslation } from 'react-i18next'

import '../assets/css/ag-grid.css'
import BuildingSelector from '../components/BuildingSelector/BuildingSelector'
import Checked from '../components/Checked'
import AgGridReact from '../components/Grid/Grid'
import NoiDefinitionSelector from '../components/NoiDefinitionSelector/NoiDefinitionSelector'
import Page from '../components/Page/LegacyPage'
import Spinner from '../components/Spinner'
import { getExpenseOutliers } from '../loaders/result'
import { getNegativeColor } from '../utils/color/result'

export const Tooltip = ({ value }) => {
  if (value) {
    return (
      <div
        style={{
          backgroundColor: 'var(--tw-color-white)',
          border: '2px solid var(--tw-color-black)',
        }}
      >
        {value.map((text) => {
          return <div key={text}>{`${text}`}</div>
        })}
      </div>
    )
  }
}

Tooltip.propTypes = {
  value: PropTypes.array.isRequired,
}

const initialState = {
  building: null,
  noiDefinition: null,
  expenseOutliersData: null,
}

const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_BUILDING': {
      if (state.building === action.data) {
        return state
      }

      return {
        ...state,
        building: action.data,
      }
    }

    case 'SET_NOI_DEFINITION': {
      if (state.noiDefinition === action.data) {
        return state
      }

      return {
        ...state,
        noiDefinition: action.data,
      }
    }

    default:
      return state
  }
}

export default function ExpenseOutliersDrilldown() {
  const { t } = useTranslation(['dashboard', 'translation'])

  const [{ building, noiDefinition }, dispatch] = useReducer(
    reducer,
    initialState
  )

  const buildingId = building?.buildingId
  const noiDefinitionId = noiDefinition?.noiDefinitionId

  const { isLoading, data: expenseOutliersData } = useQuery({
    enabled: !!buildingId && !!noiDefinitionId,
    queryKey: ['expenseOutliers', buildingId, noiDefinitionId],
    queryFn: () => getExpenseOutliers(buildingId, noiDefinitionId),
    staleTime: Infinity,
  })

  const onChangeBuilding = useCallback(
    (building) => dispatch({ type: 'SET_BUILDING', data: building }),
    [dispatch]
  )

  const onChangeNoiDefinition = useCallback(
    (noiDefinition) =>
      dispatch({ type: 'SET_NOI_DEFINITION', data: noiDefinition }),
    [dispatch]
  )

  let columnDefs = []
  let data = []
  if (expenseOutliersData) {
    columnDefs = [
      {
        headerName: 'Name',
        field: 'name',
        width: 350,
        pinned: 'left',
      },
    ]

    for (const { xAxisLabel } of expenseOutliersData[0].observations) {
      columnDefs.push({
        headerName: xAxisLabel,
        field: `${xAxisLabel}.y`,
        cellStyle({ value, data: { outlierThreshold } }) {
          if (value > outlierThreshold) {
            const color = getNegativeColor(1)

            return {
              backgroundColor: color,
            }
          }

          return {}
        },
        tooltipValueGetter: ({ data }) => {
          return data[xAxisLabel].texts
        },
        tooltipComponent: Tooltip,
      })
    }

    data = []
    for (const {
      name,
      outlierThreshold,
      observations,
    } of expenseOutliersData) {
      const row = {
        name,
        outlierThreshold,
      }

      for (const { y, texts, xAxisLabel } of observations) {
        row[xAxisLabel] = { y, texts }
      }

      data.push(row)
    }
  }

  return (
    <Checked right="result:read">
      <Page>
        <Page.Header>{t('expenseOutliers.title')}</Page.Header>
        <Page.Section id="building">
          <Page.Section.Content>
            <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
              <div key="building" className="sm:col-span-4">
                <BuildingSelector
                  selected={building}
                  onChange={onChangeBuilding}
                  allowMissing={false}
                />
              </div>
            </div>
          </Page.Section.Content>
        </Page.Section>

        <Page.Section id="noiDefinition">
          <Page.Section.Content>
            <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
              <div key="noiDefinition" className="sm:col-span-4">
                <NoiDefinitionSelector
                  selected={noiDefinition}
                  onChange={onChangeNoiDefinition}
                />
              </div>
            </div>
          </Page.Section.Content>
        </Page.Section>

        <Page.Section id="grid">
          {isLoading ? (
            <div className="flex justify-center">
              <Spinner />
            </div>
          ) : (
            <Page.Section.Content className="ag-theme-alpine">
              <AgGridReact
                domLayout="autoHeight"
                modules={[ClientSideRowModelModule]}
                columnDefs={columnDefs}
                rowData={data}
                gridOptions={{
                  tooltipShowDelay: 0,
                  tooltipHideDelay: 100000,
                }}
                rowClass="c-grid-row"
              ></AgGridReact>
            </Page.Section.Content>
          )}
        </Page.Section>
      </Page>
    </Checked>
  )
}
