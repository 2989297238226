import PropTypes from 'prop-types'
import { useCallback } from 'react'

import {
  Listbox,
  ListboxButton,
  ListboxLabel,
  ListboxOption,
  ListboxOptions,
} from '../../core/Listbox'

const BuildingFilterDropdown = ({ title, options, selected, onChange }) => {
  return (
    <Listbox value={selected} onChange={onChange}>
      {({ open }) => (
        <>
          <ListboxLabel>{title}</ListboxLabel>
          <div className="relative mt-1">
            <ListboxButton>{selected.name}</ListboxButton>
            <ListboxOptions isOpen={open}>
              {options.map((option) => (
                <ListboxOption key={option.id} value={option}>
                  {option.name}
                </ListboxOption>
              ))}
            </ListboxOptions>
          </div>
        </>
      )}
    </Listbox>
  )
}

BuildingFilterDropdown.propTypes = {
  onChange: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
  selected: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
}

const AttributeFilters = ({ attributes, selection, onChange }) => {
  const onAttributeChange = useCallback(
    (attribute, selected) => {
      const newSelection = {
        ...selection,
        [attribute.id]: selected,
      }

      onChange(newSelection)
    },
    [selection]
  )

  return (
    <>
      <div id="building-filters" className="grid grid-cols-3 gap-5">
        {attributes.map((attribute) => (
          <div key={attribute.id}>
            <BuildingFilterDropdown
              title={attribute.name}
              options={attribute.options}
              selected={selection[attribute.id]}
              onChange={(selected) => onAttributeChange(attribute, selected)}
            />
          </div>
        ))}
      </div>
    </>
  )
}

AttributeFilters.propTypes = {
  attributes: PropTypes.array.isRequired,
  selection: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
}

export default AttributeFilters
