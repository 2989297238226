import { Category } from '../../models'

export const getCategoryName = (category: Category) => {
  let name = category.businessOrigin

  if (category.type) {
    name += ` > ${category.type}`
  }

  if (category.entry) {
    name += ` > ${category.entry}`
  }

  if (category.concerning) {
    name += ` > ${category.concerning}`
  }

  if (category.specifics) {
    name += ` > ${category.specifics}`
  }

  return name
}
