import PropTypes from 'prop-types'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import {
  Listbox,
  ListboxButton,
  ListboxLabel,
  ListboxOption,
  ListboxOptions,
} from '../../core/Listbox'

export default function NoiDefinitionSelector({
  noiDefinitions,
  selected,
  onChange,
}) {
  const { t } = useTranslation(['dashboard'])

  const selectedNoiDefinition = useMemo(() => {
    if (!noiDefinitions || !selected) {
      return null
    }

    return noiDefinitions.find(
      (noiDefinition) =>
        noiDefinition.noiDefinitionId === selected.noiDefinitionId
    )
  }, [selected])

  if (!selectedNoiDefinition) {
    return null
  }

  return (
    <Listbox
      value={selectedNoiDefinition.noiDefinitionId}
      onChange={(option) => onChange(option)}
    >
      {({ open }) => (
        <>
          <ListboxLabel>{t('noiDefinitions.selector.label')}</ListboxLabel>
          <div className="relative mt-1">
            <ListboxButton>{selectedNoiDefinition.name}</ListboxButton>
            <ListboxOptions isOpen={open}>
              {noiDefinitions.map((option) => (
                <ListboxOption key={option.noiDefinitionId} value={option}>
                  {option.name}
                </ListboxOption>
              ))}
            </ListboxOptions>
          </div>
        </>
      )}
    </Listbox>
  )
}

NoiDefinitionSelector.propTypes = {
  noiDefinitions: PropTypes.array.isRequired,
  selected: PropTypes.object,
  onChange: PropTypes.func.isRequired,
}
