import {
  categoryCellEditorParams,
  categoryValueGetter,
  categoryValueSetter,
  getColumnCategories,
} from './helpers'

export default (categories) => {
  const field = 'entry'

  const columnCategories = getColumnCategories(categories, field)
  const nestingLevel = columnCategories[0].nestingLevel

  return {
    headerName: 'Entry',
    resizable: true,
    cellEditor: 'agRichSelectCellEditor',
    cellStyle: {
      color: 'green',
    },
    cellEditorPopup: true,
    valueGetter: categoryValueGetter(field),
    valueSetter: categoryValueSetter(
      field,
      nestingLevel,
      columnCategories,
      categories
    ),
    cellEditorParams: categoryCellEditorParams(field, columnCategories),
  }
}
