import _ from 'lodash'

export default {
  headerName: 'Building Owner',
  field: 'buildingOwnerAction',
  cellEditor: 'agRichSelectCellEditor',
  resizable: true,
  cellStyle: {
    color: 'green',
  },
  cellEditorPopup: true,
  valueGetter: function (params) {
    return _.get(params, ['data', 'buildingOwnerAction'])
  },
  cellEditorParams: function () {
    return {
      values: ['pays', 'paid', 'owes'],
    }
  },
  valueSetter: (params) => {
    const { newValue } = params

    if (newValue) {
      params.data.buildingOwnerAction = newValue

      return true
    }

    return false
  },
}
