import { Outlet } from 'react-router-dom'

import Nav from '../Nav/Nav'
import Toaster from '../ToastProvider'

const App = () => {
  return (
    <>
      <Nav>
        <Outlet />
      </Nav>
      <Toaster />
    </>
  )
}

export default App
