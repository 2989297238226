import { useMutation } from '@tanstack/react-query'
import _ from 'lodash'
import { useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { toast } from 'react-hot-toast'
import { useTranslation } from 'react-i18next'

import Button from '../components/core/Button/Button'
import {
  Form,
  FormField,
  FormFieldGrid,
  FormLabel,
  FormSection,
} from '../components/core/Form/Form'
import Select from '../components/core/Form/Select'
import Input from '../components/core/Input/Input'
import { updateAgreement } from '../loaders/vendors'
import { VendorAgreement } from '../models'

const recurringCostFrequencies = ['month', 'quarter', 'year']

type FormValues = Partial<
  Pick<
    VendorAgreement,
    'startDate' | 'endDate' | 'recurringCost' | 'recurringCostFrequency'
  >
>

interface EditVendorAgreementProps {
  agreement: VendorAgreement
  onSaved?: () => void
  onCanceled?: () => void
}

const EditVendorAgreement = ({
  agreement,
  onSaved,
  onCanceled,
}: EditVendorAgreementProps) => {
  const { t } = useTranslation(['dashboard', 'translation'])

  // Set up form
  const { register, handleSubmit, reset, control } = useForm<FormValues>({
    defaultValues: {
      recurringCostFrequency: 'month',
    },
  })

  useEffect(() => {
    if (agreement) {
      reset(agreement)
    }
  }, [agreement])

  const { mutate: saveMutation, isLoading: isSaving } = useMutation({
    mutationFn: (data: FormValues) => updateAgreement(agreement.id, data),
    onSuccess: () => {
      onSaved?.()
    },
    onError: (error: Error) => {
      toast.error(
        t('editVendorAgreement.notification.error', {
          error: error.toString(),
        })
      )
    },
  })

  const onSubmit = handleSubmit((data) => saveMutation(data))

  return (
    <Form onSubmit={onSubmit}>
      <FormSection>
        <FormFieldGrid>
          <FormField>
            <FormLabel htmlFor="startDate">
              {t('editVendorAgreement.form.startDate')}
            </FormLabel>
            <Input
              id="startDate"
              type="date"
              {...register('startDate', {
                setValueAs: (value) => (_.isEmpty(value) ? null : value),
              })}
            />
          </FormField>
          <FormField>
            <FormLabel htmlFor="endDate">
              {t('editVendorAgreement.form.endDate')}
            </FormLabel>
            <Input
              id="endDate"
              type="date"
              {...register('endDate', {
                setValueAs: (value) => (_.isEmpty(value) ? null : value),
              })}
            />
          </FormField>
          <FormField>
            <FormLabel htmlFor="recurringCost">
              {t('editVendorAgreement.form.recurringCost')}
            </FormLabel>
            <Input
              id="recurringCost"
              type="number"
              step="any"
              {...register('recurringCost', {
                valueAsNumber: true,
              })}
            />
          </FormField>
          <FormField>
            <FormLabel htmlFor="recurringCostFrequency">
              {t('editVendorAgreement.form.recurringCostFrequency')}
            </FormLabel>
            <Controller
              name="recurringCostFrequency"
              control={control}
              render={({ field: { onChange, value } }) => (
                <Select
                  options={
                    recurringCostFrequencies.map((frequency) => ({
                      id: frequency,
                      name: t(`recurringCostFrequency.${frequency}`),
                    })) ?? []
                  }
                  selectedOption={{
                    id: value ?? 'month',
                    name: t(`recurringCostFrequency.${value || 'month'}`),
                  }}
                  onChange={(option) => onChange(option.id)}
                />
              )}
            />
          </FormField>
        </FormFieldGrid>
      </FormSection>
      <div className="mt-6 flex justify-end gap-x-4">
        <Button variant="neutral" onClick={onCanceled} type="button">
          {t('form.cancel', { ns: 'translation' })}
        </Button>
        <Button variant="primary" type="submit" disabled={isSaving}>
          {t('form.save', { ns: 'translation' })}
        </Button>
      </div>
    </Form>
  )
}

export default EditVendorAgreement
