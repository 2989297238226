import { useTranslation } from 'react-i18next'

import { Switch, SwitchGroup, SwitchLabel } from '../../components/core/Switch'

export interface AbsoluteSwitchProps {
  enabled: boolean
  onChange: (checked: boolean) => void
}

export default function AbsoluteSwitch({
  enabled,
  onChange,
}: AbsoluteSwitchProps) {
  const { t } = useTranslation(['dashboard'])

  return (
    <SwitchGroup as="div" className="flex justify-left">
      <Switch enabled={enabled} onChange={onChange}></Switch>
      <SwitchLabel as="span" className="ml-3 text-sm">
        {t('absoluteSwitch.label')}
      </SwitchLabel>
    </SwitchGroup>
  )
}
