import { EyeIcon } from '@heroicons/react/24/outline'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'

import AbsoluteSwitch from '../../AbsoluteSwitch/AbsoluteSwitch'
import ExcludeIncompleteMonthsSwitch from '../../ExcludeIncompleteMonthsSwitch/ExcludeIncompleteMonthsSwitch'
import Spinner from '../../Spinner'
import Popover, { PopoverSection, PopoverSections } from '../../core/Popover'
import NoiDefinitionSelector from './NoiDefinitionSelector'

function ViewPopover({
  noiDefinitions,
  noiDefinition,
  setNoiDefinition,
  absolute,
  setAbsolute,
  excludeIncompleteMonths,
  setExcludeIncompleteMonths,
}) {
  const [label, setLabel] = useState('')

  useEffect(() => {
    if (noiDefinition) {
      setLabel(noiDefinition.name)
    }
  }, [noiDefinition])

  return (
    <Popover
      text={label}
      icon={<EyeIcon aria-hidden="true" className="h-5 w-5" />}
    >
      <PopoverSections>
        {noiDefinitions ? (
          <>
            <PopoverSection>
              <NoiDefinitionSelector
                noiDefinitions={noiDefinitions}
                selected={noiDefinition}
                onChange={setNoiDefinition}
              />
            </PopoverSection>
            <PopoverSection>
              <div className="flex flex-col gap-y-2">
                <AbsoluteSwitch enabled={absolute} onChange={setAbsolute} />
                <ExcludeIncompleteMonthsSwitch
                  enabled={excludeIncompleteMonths}
                  onChange={setExcludeIncompleteMonths}
                />
              </div>
            </PopoverSection>
          </>
        ) : (
          <PopoverSection>
            <Spinner />
          </PopoverSection>
        )}
      </PopoverSections>
    </Popover>
  )
}

ViewPopover.propTypes = {
  noiDefinitions: PropTypes.array,
  noiDefinition: PropTypes.object,
  setNoiDefinition: PropTypes.func,
  absolute: PropTypes.bool,
  setAbsolute: PropTypes.func,
  excludeIncompleteMonths: PropTypes.bool,
  setExcludeIncompleteMonths: PropTypes.func,
}

export default ViewPopover
