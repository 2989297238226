import classnames from 'classnames'

interface Row {
  cells: React.ReactNode[]
  data?: any // eslint-disable-line @typescript-eslint/no-explicit-any
}

interface TableProps {
  headerCells: React.ReactNode[]
  rows: Row[]
  onRowClick?: (rowData: any) => void // eslint-disable-line @typescript-eslint/no-explicit-any
  isLoading?: boolean
  loadingRowCount?: number
}

const LoadingRows = ({
  columns,
  rowCount,
}: {
  columns: number
  rowCount: number
}) => {
  return (
    <>
      {Array.from({ length: rowCount }).map((_, index) => (
        <tr key={index} className="animate-pulse">
          <td className="h-14 px-6 py-3 bg-white" colSpan={columns}>
            <div className="h-full w-full rounded-md bg-gray-200"></div>
          </td>
        </tr>
      ))}
    </>
  )
}

const Table = ({
  headerCells,
  rows,
  onRowClick,
  isLoading,
  loadingRowCount = 3,
}: TableProps) => {
  return (
    <>
      <table className="w-full table-auto divide-y divide-gray-200 bg-white">
        <thead>
          <tr>
            {headerCells.map((cell, index) => (
              <th
                key={index}
                className={classnames(
                  'pl-4 pr-3 text-left text-sm font-semibold text-gray-900 py-3 px-3 sm:px-6',
                  {
                    'text-right': index === headerCells.length - 1,
                  }
                )}
              >
                {cell}
              </th>
            ))}
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-200">
          {isLoading ? (
            <LoadingRows
              columns={headerCells.length}
              rowCount={loadingRowCount}
            />
          ) : (
            rows.map((row, rowIndex) => (
              <tr
                key={rowIndex}
                className={classnames('h-14', {
                  'hover:bg-gray-50 cursor-pointer': !!onRowClick,
                })}
                onClick={() => onRowClick?.(row.data)}
              >
                {row.cells.map((cell, cellIndex) => (
                  <td
                    key={cellIndex}
                    className={classnames(
                      'whitespace-nowrap text-sm text-gray-700 py-3 px-3 sm:px-6',
                      {
                        'text-right': cellIndex === row.cells.length - 1,
                      }
                    )}
                  >
                    {cell}
                  </td>
                ))}
              </tr>
            ))
          )}
        </tbody>
      </table>
    </>
  )
}

export default Table
