import PropTypes from 'prop-types'

Stories.propTypes = {
  stories: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
}

export default function Stories({ stories }) {
  const divs = []
  let counter = 1

  for (const story of stories) {
    divs.push(
      <dt className="text-base text-ps-500" key={`${counter}`}>
        <div>{story}</div>
      </dt>
    )

    counter += 1
  }

  return divs
}
