import classnames from 'classnames'
import { ComponentPropsWithoutRef } from 'react'

import { useFieldContext } from './InputField'

interface LabelProps extends ComponentPropsWithoutRef<'label'> {
  className?: string
  htmlFor?: string
}

const Label = ({ className, children, htmlFor }: LabelProps) => {
  const context = useFieldContext()

  return (
    <label
      className={classnames(
        'block text-sm font-medium text-gray-900',
        className
      )}
      htmlFor={htmlFor || context.id}
    >
      {children}
    </label>
  )
}

export default Label
