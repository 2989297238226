import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react'

import Modal from './Modal'

interface ModalContextType {
  showModal: (title: string, content: ReactNode) => void
  hideModal: () => void
}

const ModalContext = createContext<ModalContextType>({
  showModal: (title: string, content: ReactNode) => {}, // eslint-disable-line @typescript-eslint/no-unused-vars
  hideModal: () => {},
})

ModalContext.displayName = 'ModalContext'

export function useModal() {
  return useContext(ModalContext)
}

export function ModalProvider({ children }: { children: ReactNode }) {
  const [open, setOpen] = useState(false)
  const [title, setTitle] = useState<string | null>(null)
  const [content, setContent] = useState<ReactNode | null>(null)

  const showModal = useCallback(
    (title: string, content: ReactNode) => {
      setTitle(title)
      setContent(content)
      setOpen(true)
    },
    [setTitle, setContent, setOpen]
  )

  const hideModal = useCallback(() => {
    setOpen(false)
  }, [setOpen])

  const onClosed = useCallback(() => {
    if (!open) {
      setTitle(null)
      setContent(null)
    }
  }, [open, setTitle, setContent])

  const value = useMemo(
    () => ({ showModal, hideModal }),
    [showModal, hideModal]
  )

  return (
    <ModalContext.Provider value={value}>
      <Modal
        open={open}
        setOpen={setOpen}
        title={title}
        content={content}
        onClosed={onClosed}
      />
      {children}
    </ModalContext.Provider>
  )
}
