import { useQuery } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'

import BusinessCentralConnect from '../components/BusinessCentralConnect/BusinessCentralConnect'
import BusinessCentralSettings from '../components/BusinessCentralSettings/BusinessCentralSettings'
import Checked from '../components/Checked'
import Page from '../components/Page/LegacyPage'
import { getAppConnection } from '../loaders/apps'

export default function BusinessCentralApp() {
  const { t } = useTranslation(['dashboard'])

  const { data: connection, isLoading } = useQuery({
    queryKey: ['business_central_app'],
    queryFn: () => getAppConnection('business_central'),
  })

  if (isLoading) {
    return null
  }

  return (
    <Checked right="app:admin">
      <Page.Header>{t('apps.businessCentral.name')}</Page.Header>
      <Page.Section>
        {connection ? (
          <BusinessCentralSettings connection={connection} />
        ) : (
          <BusinessCentralConnect />
        )}
      </Page.Section>
    </Checked>
  )
}
