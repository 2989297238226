import {
  PropsWithChildren,
  createContext,
  useContext,
  useId,
  useMemo,
} from 'react'

interface FieldContextProps {
  id?: string
}

const FieldContext = createContext<FieldContextProps>({})

export const useFieldContext = () => {
  return useContext(FieldContext)
}

interface InputFieldProps {
  id?: string
}

const InputField = ({ id, children }: PropsWithChildren<InputFieldProps>) => {
  const uuid = useId()
  const value = useMemo(() => ({ id: id || uuid }), [id, uuid])

  return <FieldContext.Provider value={value}>{children}</FieldContext.Provider>
}

export default InputField
