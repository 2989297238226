import {
  Dialog,
  DialogBackdrop,
  DialogPanel,
  TransitionChild,
} from '@headlessui/react'
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline'
import { ComponentPropsWithoutRef, useState } from 'react'

import { DrawerProvider } from '../core/Drawer/DrawerProvider'
import { ModalProvider } from '../core/Modal/ModalProvider'
import CompanyLogo from './CompanyLogo/CompanyLogo'
import ProfileMenu from './ProfileMenu'
import Sidebar from './Sidebar/Sidebar'

interface MobileSidebarProps {
  open: boolean
  setOpen: (open: boolean) => void
}

const MobileSidebar = ({ open, setOpen }: MobileSidebarProps) => {
  return (
    <Dialog open={open} onClose={setOpen} className="relative z-40 lg:hidden">
      <DialogBackdrop
        transition
        className="fixed inset-0 bg-gray-900/80 transition-opacity duration-300 ease-linear data-[closed]:opacity-0"
      />

      <div className="fixed inset-0 flex">
        <DialogPanel
          transition
          className="relative mr-16 flex w-full max-w-xs flex-1 transform transition duration-300 ease-in-out data-[closed]:-translate-x-full"
        >
          <TransitionChild>
            <div className="absolute left-full top-0 flex w-16 justify-center pt-5 duration-300 ease-in-out data-[closed]:opacity-0">
              <button
                type="button"
                onClick={() => setOpen(false)}
                className="-m-2.5 p-2.5"
              >
                <span className="sr-only">Close sidebar</span>
                <XMarkIcon aria-hidden="true" className="h-6 w-6 text-white" />
              </button>
            </div>
          </TransitionChild>
          <Sidebar />
        </DialogPanel>
      </div>
    </Dialog>
  )
}

interface MobileNavbarProps {
  setSidebarOpen: (open: boolean) => void
}

const MobileNavbar = ({ setSidebarOpen }: MobileNavbarProps) => {
  return (
    <div className="sticky top-0 z-30 flex items-center justify-between bg-white pl-4 shadow-sm lg:hidden">
      <button
        type="button"
        onClick={() => setSidebarOpen(true)}
        className="-m-2.5 p-2.5 text-gray-700 lg:hidden"
      >
        <Bars3Icon aria-hidden="true" className="h-6 w-6" />
      </button>
      <div className="flex">
        <CompanyLogo />
      </div>
      <ProfileMenu />
    </div>
  )
}

const DesktopSidebar = () => {
  return (
    <div className="hidden lg:fixed lg:inset-y-0 lg:z-40 lg:flex lg:w-72 lg:flex-col">
      <Sidebar />
    </div>
  )
}

export default function Nav({ children }: ComponentPropsWithoutRef<'div'>) {
  const [sidebarOpen, setSidebarOpen] = useState(false)

  return (
    <ModalProvider>
      <DrawerProvider>
        <div>
          <MobileSidebar open={sidebarOpen} setOpen={setSidebarOpen} />
          <MobileNavbar setSidebarOpen={setSidebarOpen} />
          <DesktopSidebar />
          <main className="py-10 lg:pl-72">
            <div className="px-4 sm:px-6 lg:px-8">{children}</div>
          </main>
        </div>
      </DrawerProvider>
    </ModalProvider>
  )
}
