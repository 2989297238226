import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import { Switch, SwitchGroup, SwitchLabel } from '../../components/core/Switch'

export default function AnnualizeSwitch({ enabled, onChange }) {
  const { t } = useTranslation(['dashboard'])

  return (
    <SwitchGroup as="div" className="flex justify-left">
      <Switch enabled={enabled} onChange={onChange}></Switch>
      <SwitchLabel as="span" className="ml-3 text-sm">
        {t('annualizeSwitch.label')}
      </SwitchLabel>
    </SwitchGroup>
  )
}

AnnualizeSwitch.propTypes = {
  enabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
}
