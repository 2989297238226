import { ChevronRightIcon } from '@heroicons/react/24/outline'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import useNumberOptions from '../../hooks/useNumberOptions'
import { Anomaly } from '../../models'
import { formatDate, formatNumber } from '../../utils/format'
import DocumentList, { DocumentCard } from '../DocumentList/DocumentList'
import Button from '../core/Button/Button'
import {
  Form,
  FormField,
  FormFieldGrid,
  FormLabel,
  FormSection,
  FormValue,
} from '../core/Form/Form'
import Markdown from '../core/Markdown/Markdown'
import Number from '../core/Number/Number'

const VendorInvoiceSection = ({ anomaly }: ReferenceFieldsProps) => {
  const { t } = useTranslation(['dashboard'])
  const numberOptions = useNumberOptions({
    isCurrency: true,
  })

  const invoice = useMemo(() => {
    return anomaly.vendorInvoice
  }, [anomaly])

  return (
    <FormSection title={t(`anomalyDetail.reference.vendor_invoice.title`)}>
      <FormFieldGrid>
        <FormField>
          <FormLabel>
            {t('anomalyDetail.reference.vendor_invoice.invoiceNumber')}
          </FormLabel>
          <FormValue>{invoice?.invoiceNumber}</FormValue>
        </FormField>
        <FormField>
          <FormLabel>
            {t('anomalyDetail.reference.vendor_invoice.vendor')}
          </FormLabel>
          <FormValue>
            <Button variant="soft" size="xs" className="rounded-full">
              <Link to={`/vendors?vendorId=${invoice?.vendor?.id}`}>
                {invoice?.vendor?.name}
              </Link>
              <ChevronRightIcon className="ml-1 h-3 w-3" />
            </Button>
          </FormValue>
        </FormField>

        <FormField>
          <FormLabel>
            {t('anomalyDetail.reference.vendor_invoice.amount')}
          </FormLabel>
          <FormValue>
            <Number value={invoice?.amount} isCurrency={true} />
          </FormValue>
        </FormField>
        <FormField>
          <FormLabel>
            {t('anomalyDetail.reference.vendor_invoice.purchaseDate')}
          </FormLabel>
          <FormValue>{formatDate(invoice?.purchaseDate)}</FormValue>
        </FormField>
        {invoice?.document && (
          <FormField columns={6}>
            <FormLabel>
              {t('anomalyDetail.reference.vendor_invoice.document')}
            </FormLabel>
            <FormValue>
              <DocumentList>
                <DocumentCard
                  key={invoice?.id}
                  document={invoice?.document}
                  leftText={`${formatNumber(invoice?.amount || 0, numberOptions)}`}
                  rightText={`${formatNumber(invoice?.vatAmount || 0, numberOptions)} (VAT)`}
                />
              </DocumentList>
            </FormValue>
          </FormField>
        )}
      </FormFieldGrid>
    </FormSection>
  )
}

interface ReferenceFieldsProps {
  anomaly: Anomaly
}

const ReferenceSection = ({ anomaly }: ReferenceFieldsProps) => {
  switch (anomaly.referenceType) {
    case 'vendor_invoice':
      return <VendorInvoiceSection anomaly={anomaly} />
  }
}

interface AnomalyDetailProps {
  anomaly: Anomaly
}

const AnomalyDetail = ({ anomaly }: AnomalyDetailProps) => {
  const { t } = useTranslation(['dashboard'])

  return (
    <Form>
      <FormSection title={t('anomalyDetail.general')}>
        <FormFieldGrid>
          <FormField columns={6}>
            <FormLabel>{t('anomalyDetail.analysis')}</FormLabel>
            <FormValue>
              <Markdown>{anomaly.text}</Markdown>
            </FormValue>
          </FormField>
        </FormFieldGrid>
      </FormSection>
      <ReferenceSection anomaly={anomaly} />
    </Form>
  )
}

export default AnomalyDetail
