import {
  AdjustmentsHorizontalIcon,
  CheckCircleIcon,
  MagnifyingGlassCircleIcon,
  Squares2X2Icon,
  UsersIcon,
  WrenchScrewdriverIcon,
} from '@heroicons/react/24/outline'

import { getCount as getAnomalyCount } from '../../loaders/anomalies'

export interface NavItem {
  label: string
  href: string
  right?: string
  icon?: React.ElementType
  countLoader?: () => Promise<number>
}

export interface NavGroupItem extends NavItem {
  scope: string
  children: NavItem[]
}

const navigation: (NavItem | NavGroupItem)[] = [
  {
    label: 'nav.overview',
    href: '/',
    right: 'result:read',
    icon: Squares2X2Icon,
  },
  {
    label: 'nav.followup',
    href: '/follow-up',
    icon: CheckCircleIcon,
    countLoader: getAnomalyCount,
  },
  {
    label: 'nav.tenants',
    href: '/tenants',
    right: 'tenant:read',
    icon: UsersIcon,
  },
  {
    label: 'nav.vendors',
    href: '/vendors',
    right: 'vendor:read',
    icon: WrenchScrewdriverIcon,
  },
  {
    label: 'nav.drilldown',
    href: '/drilldown/timelines',
    scope: '/drilldown/*',
    icon: MagnifyingGlassCircleIcon,

    children: [
      {
        label: 'nav.timelinesDrilldown',
        href: '/drilldown/timelines',
        right: 'result:read',
      },
      {
        label: 'nav.recordsDrilldown',
        href: '/drilldown/records',
        right: 'result:read',
      },
      {
        label: 'nav.expenseOutliersDrilldown',
        href: '/drilldown/expense_outliers',
        right: 'result:read',
      },
      {
        label: 'nav.buildingsDrilldown',
        href: '/drilldown/buildings',
        right: 'result:read',
      },
    ],
  },
  {
    label: 'nav.dataRoom',
    href: '/setup/buildings',
    scope: '/setup/*',
    icon: WrenchScrewdriverIcon,

    children: [
      {
        label: 'nav.buildings',
        href: '/setup/buildings',
        right: 'building:write',
      },
      {
        label: 'nav.accountCharts',
        href: '/setup/account_charts',
        right: 'account_chart:write',
      },
      {
        label: 'nav.records',
        href: '/setup/records',
        right: 'record:write',
      },
      {
        label: 'nav.metrics',
        href: '/setup/metrics',
        right: 'metric:write',
      },
      {
        label: 'nav.internalBenchmarks',
        href: '/setup/internal_benchmarks',
        right: 'internal_benchmark:write',
      },
    ],
  },
  {
    label: 'nav.admin',
    href: '/admin/organization',
    scope: '/admin/*',
    icon: AdjustmentsHorizontalIcon,

    children: [
      {
        label: 'nav.organization',
        href: '/admin/organization',
        right: 'org:admin',
      },
      {
        label: 'nav.users',
        href: '/admin/users',
        right: 'user:admin',
      },
      {
        label: 'nav.apps',
        href: '/admin/apps',
        right: 'app:admin',
      },
    ],
  },
]

export default navigation
