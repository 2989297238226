import classnames from 'classnames'
import { Toaster, toast } from 'react-hot-toast'
import { useTranslation } from 'react-i18next'

const ToastProvider = () => {
  const { t: translate } = useTranslation()

  return (
    <Toaster position="bottom-right">
      {(t) => (
        <div
          className={classnames(
            'max-w-md w-full bg-white shadow-lg rounded-lg pointer-events-auto flex ring-1 ring-black ring-opacity-5',
            {
              'animate-enter': t.visible,
              'animate-leave': !t.visible,
            }
          )}
        >
          <div className="flex-1 w-0 p-4">
            <div className="flex items-center">
              <p className="text-sm font-medium text-gray-900">{t.message}</p>
            </div>
          </div>
          <div className="flex border-l border-gray-200">
            <button
              onClick={() => toast.remove(t.id)}
              className={classnames(
                'w-full border border-transparent rounded-none rounded-r-lg p-4 flex items-center justify-center text-sm font-medium',
                'text-ps-600 hover:text-ps-500 focus:outline-none focus:ring-2 focus:ring-ps-500'
              )}
            >
              {translate('form.close')}
            </button>
          </div>
        </div>
      )}
    </Toaster>
  )
}

export default ToastProvider
