import { useQuery } from '@tanstack/react-query'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'

import { GridCard } from '../components/Card/Card'
import Checked from '../components/Checked'
import { Header, Page, Section } from '../components/Page/Page'
import PaginationNav from '../components/PaginationNav/PaginationNav'
import Table from '../components/Table/Table'
import { VendorDetail } from '../components/VendorDetail/VendorDetail'
import Button from '../components/core/Button/Button'
import { useDrawer } from '../components/core/Drawer/DrawerProvider'
import Number from '../components/core/Number/Number'
import { getVendor, getVendors } from '../loaders/vendors'
import { Vendor } from '../models'
import { formatDate } from '../utils/format'

const Vendors = () => {
  const { t } = useTranslation(['dashboard'])
  const navigate = useNavigate()

  const [searchParams, setSearchParams] = useSearchParams()
  const { showDrawer } = useDrawer()

  const [page, setPage] = useState(1)

  const showVendor = useCallback(
    (vendor: Vendor) => {
      showDrawer(vendor.name, <VendorDetail vendor={vendor} />)
    },
    [showDrawer]
  )

  // Load vendors
  const { data, isLoading } = useQuery({
    queryKey: ['vendors', page],
    queryFn: () => getVendors({ page }),
  })

  const vendors = useMemo(() => data?.data ?? [], [data])
  const pagination = useMemo(() => data?.pagination, [data])

  // Load initial vendor from search param if present
  const initialVendorId = searchParams.has('vendorId')
    ? parseInt(searchParams.get('vendorId')!)
    : null

  const { data: initialVendor } = useQuery({
    enabled: !!initialVendorId,
    queryKey: ['vendor', initialVendorId],
    queryFn: () => getVendor(initialVendorId!),
    onSuccess: (vendor) => {
      showVendor(vendor)
    },
    onError: () => {
      setSearchParams({})
    },
  })

  useEffect(() => {
    if (initialVendor) {
      showVendor(initialVendor)
    }
  }, [initialVendor])

  return (
    <Checked right="vendor:read">
      <Page>
        <Header
          subtitle={t('vendors.subtitle') ?? ''}
          actions={
            <>
              <Button variant="primary" className="ml-2">
                <Link to="/vendors/new">{t('vendors.create')}</Link>
              </Button>
            </>
          }
        >
          {t('vendors.title')}
        </Header>
        <Section>
          <GridCard>
            <Table
              isLoading={isLoading}
              headerCells={[
                t('vendors.column.name'),
                t('vendors.column.building'),
                t('vendors.column.recurringCost'),
                t('vendors.column.recurringCostFrequency'),
                t('vendors.column.startDate'),
                t('vendors.column.endDate'),
                <span key="actions" className="sr-only">
                  {t('vendors.column.actions')}
                </span>,
              ]}
              rows={vendors.map((vendor) => ({
                data: vendor,
                cells: [
                  <span key="name" className="font-medium">
                    {vendor.name}
                  </span>,
                  vendor.building?.buildingReference,
                  <Number
                    key="recurringCost"
                    value={vendor.agreements[0]?.recurringCost}
                    isCurrency={true}
                  />,
                  vendor.agreements[0]?.recurringCostFrequency
                    ? t(
                        `recurringCostFrequency.${vendor.agreements[0]?.recurringCostFrequency}`
                      )
                    : '-',
                  formatDate(vendor.agreements[0]?.startDate),
                  formatDate(vendor.agreements[0]?.endDate),
                  <Button
                    key="edit"
                    variant="neutral"
                    onClick={() => navigate(`/vendors/${vendor.id}/edit`)}
                  >
                    {t('vendors.button.edit')}
                  </Button>,
                ],
              }))}
              onRowClick={(vendor) => showVendor(vendor)}
            />
            {pagination && pagination.pageCount > 1 && (
              <PaginationNav
                pagination={pagination}
                onPrevious={() => setPage(pagination?.previousPage ?? 1)}
                onNext={() => setPage(pagination?.nextPage ?? 1)}
              />
            )}
          </GridCard>
        </Section>
      </Page>
    </Checked>
  )
}

export default Vendors
