import { useMutation, useQuery } from '@tanstack/react-query'
import { useCallback, useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'

import Card from '../components/Card/Card'
import Checked from '../components/Checked'
import Page from '../components/Page/LegacyPage'
import Button from '../components/core/Button/Button'
import {
  Form,
  FormField,
  FormFieldGrid,
  FormLabel,
  FormSection,
  FormValue,
} from '../components/core/Form/Form'
import Input from '../components/core/Input/Input'
import { getOrganization, updateOrganization } from '../loaders/organizations'

export default function Apps() {
  const { t } = useTranslation(['dashboard'])

  const [state, setState] = useState({
    name: '',
    senderEmails: [],
  })

  const {
    data: organization,
    status,
    isLoading,
  } = useQuery(['organization'], getOrganization)

  const saveMutation = useMutation({
    mutationFn: updateOrganization,
  })

  useEffect(() => {
    if (status === 'success') {
      setState({
        name: organization.name,
        senderEmails: organization.senderEmails,
      })
    }
  }, [status, organization])

  const onSubmit = useCallback(async (event) => {
    event.preventDefault()
    saveMutation.mutate(
      {
        name: state.name,
        senderEmails: state.senderEmails,
      },
      {
        onSuccess: () => {
          toast.success(t('organization.notification.success'))
        },
        onError: (error) => {
          toast.error(
            t('organization.notification.error', {
              error: error.toString(),
            })
          )
        },
      }
    )
  })

  if (isLoading) {
    return null
  }

  return (
    <Checked right="org:admin">
      <Page>
        <Page.Header>{t('organization.title')}</Page.Header>
        <Page.Section>
          <Card>
            <Form onSubmit={onSubmit}>
              <FormSection>
                <FormFieldGrid>
                  <FormField columns={6}>
                    <FormLabel>{t(`organization.field.name.label`)}</FormLabel>
                    <FormValue>
                      <Input
                        type="text"
                        name="name"
                        description={t(`organization.field.name.description`)}
                        value={state.name}
                        onChange={(event) =>
                          setState((prev) => ({
                            ...prev,
                            name: event.target.value,
                          }))
                        }
                      />
                    </FormValue>
                  </FormField>
                  <FormField columns={6}>
                    <FormLabel>
                      {t(`organization.field.senderEmails.label`)}
                    </FormLabel>
                    <FormValue>
                      <Input
                        type="text"
                        name="senderEmails"
                        description={t(
                          `organization.field.senderEmails.description`
                        )}
                        value={state?.senderEmails?.join(',')}
                        onChange={(event) =>
                          setState((prev) => ({
                            ...prev,
                            senderEmails:
                              event.target.value !== ''
                                ? event.target.value.split(',')
                                : [],
                          }))
                        }
                      />
                    </FormValue>
                  </FormField>
                </FormFieldGrid>
              </FormSection>
              <div className="mt-6 flex justify-end gap-x-4">
                <Button variant="primary" type="submit">
                  {t('form.save', { ns: 'translation' })}
                </Button>
              </div>
            </Form>
          </Card>
        </Page.Section>
      </Page>
    </Checked>
  )
}
