import { useMemo } from 'react'

import useOrganization from './useOrganization'
import useSettings from './useSettings'

interface NumberOptions {
  locale?: string
  currency?: string
}

export default function useNumberOptions({
  isCurrency = false,
  currency,
}: {
  isCurrency?: boolean
  currency?: string
} = {}) {
  const { settings } = useSettings()
  const { organization } = useOrganization()

  return useMemo(() => {
    const options: NumberOptions = {
      locale: settings?.numberLocale,
    }

    if (isCurrency) {
      options.currency = currency ?? organization?.currency
    }

    return options
  }, [settings, organization, isCurrency, currency])
}
