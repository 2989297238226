import { Vendor, VendorAgreement, VendorInvoice } from '../models'
import httpClient from '../utils/httpClient'
import { fromJsonApi, fromJsonApiWithPagination } from '../utils/jsonapi'

export const getVendors = async ({
  page = 1,
  pageSize,
}: {
  page: number
  pageSize?: number
}) => {
  const response = await httpClient.get(`/vendor`, {
    params: {
      include: 'building,agreements,agreements.vendor,agreements.document',
      page,
      pageSize,
    },
  })

  return fromJsonApiWithPagination<Vendor[]>(response.data)
}

export const getVendor = async (vendorId: number) => {
  const response = await httpClient.get(`/vendor/${vendorId}`, {
    params: {
      include: 'building,agreements,agreements.vendor,agreements.document',
    },
  })
  return fromJsonApi<Vendor>(response.data)
}

export const createVendor = async ({
  name,
  buildingId,
}: {
  name: string
  buildingId: number
}) => {
  const response = await httpClient.post('/vendor', { name, buildingId })
  return fromJsonApi<Vendor>(response.data)
}

export const updateVendor = async (
  vendorId: number,
  { name, buildingId }: { name?: string; buildingId?: number }
) => {
  const response = await httpClient.patch(`/vendor/${vendorId}`, {
    name,
    buildingId,
  })
  return fromJsonApi<Vendor>(response.data)
}

export const deleteVendor = async (vendorId: number) => {
  await httpClient.delete(`/vendor/${vendorId}`)
}

export const updateAgreement = async (
  vendorAgreementId: number,
  {
    startDate,
    endDate,
    oneTimeCost,
    recurringCost,
    recurringCostFrequency,
  }: Partial<VendorAgreement>
) => {
  const response = await httpClient.patch(
    `/vendor/agreements/${vendorAgreementId}`,
    {
      startDate,
      endDate,
      oneTimeCost,
      recurringCost,
      recurringCostFrequency,
    }
  )

  return fromJsonApi<VendorAgreement>(response.data)
}

export const createAgreement = async (
  vendorId: number,
  name: string,
  location: string
) => {
  const response = await httpClient.post(
    `/vendor/${vendorId}/agreements`,
    {
      name,
      location,
    },
    {
      params: {
        include: 'document',
      },
    }
  )

  return fromJsonApi<VendorAgreement>(response.data)
}

export const deleteAgreement = async (vendorAgreementId: number) => {
  await httpClient.delete(`/vendor/agreements/${vendorAgreementId}`)
}

export const getInvoices = async (
  vendorId: number,
  { page, pageSize }: { page?: number; pageSize?: number } = {}
) => {
  const response = await httpClient.get(`/vendor/${vendorId}/invoices`, {
    params: {
      include: 'document',
      page,
      pageSize,
    },
  })
  return fromJsonApiWithPagination<VendorInvoice[]>(response.data)
}

export const createInvoice = async (
  vendorId: number,
  name: string,
  location: string
): Promise<VendorInvoice> => {
  const response = await httpClient.post(
    `/vendor/${vendorId}/invoices`,
    {
      name,
      location,
    },
    {
      params: {
        include: 'document',
      },
    }
  )
  return fromJsonApi<VendorInvoice>(response.data)
}

export const deleteInvoice = async (invoiceId: number): Promise<void> => {
  await httpClient.delete(`/vendor/invoices/${invoiceId}`)
}
