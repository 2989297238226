import { useMutation } from '@tanstack/react-query'
import { useEffect, useState } from 'react'

import { createAgreement, deleteAgreement } from '../../loaders/tenants'
import { Tenant, TenantAgreement } from '../../models'
import { formatDate } from '../../utils/format'
import { formatNumber } from '../../utils/format'
import DocumentList, {
  DocumentCard,
  UploadCard,
} from '../DocumentList/DocumentList'

interface AgreementListProps {
  tenant: Tenant
}

const AgreementList = ({ tenant }: AgreementListProps) => {
  const [agreements, setAgreements] = useState(tenant.agreements)

  useEffect(() => {
    if (tenant.agreements) {
      setAgreements(tenant.agreements)
    }
  }, [tenant])

  const { mutate: createMutation } = useMutation({
    mutationFn: ({ name, location }: { name: string; location: string }) =>
      createAgreement(tenant.id, name, location),
    onSuccess: (agreement) => {
      setAgreements([...agreements, agreement])
    },
  })

  const { mutate: deleteMutation } = useMutation({
    mutationFn: (agreement: TenantAgreement) => deleteAgreement(agreement.id),
    onSuccess: (_, agreement) => {
      const newAgreements = agreements.filter((a) => a.id !== agreement.id)
      setAgreements(newAgreements)
    },
  })

  return (
    <DocumentList>
      {agreements.map((agreement) => (
        <DocumentCard
          key={agreement.id}
          document={agreement.document!}
          leftText={formatDate(agreement.startDate)}
          rightText={formatNumber(agreement.rentAmount) ?? ''}
          onDelete={() => deleteMutation(agreement)}
        />
      ))}
      <UploadCard
        id="agreement-upload"
        onUpload={(name, location) => createMutation({ name, location })}
      />
    </DocumentList>
  )
}

export default AgreementList
