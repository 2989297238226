import Logo from '../../Logo'

const name = 'Propsponge'

const CompanyLogo = () => {
  return (
    <div className="flex flex-shrink-0 items-center text-base font-sora">
      <Logo className="block h-6 w-auto rounded-sm lg:hidden" altText={name} />
      <Logo className="hidden h-6 w-auto rounded-md lg:block" altText={name} />
      <span className="ml-2 font-semibold text-brand-900">{name}</span>
    </div>
  )
}

export default CompanyLogo
