import { Tenant, TenantAgreement } from '../models'
import httpClient from '../utils/httpClient'
import { fromJsonApi, fromJsonApiWithPagination } from '../utils/jsonapi'

export const getTenants = async ({
  page,
  pageSize,
}: {
  page: number
  pageSize?: number
}) => {
  const response = await httpClient.get(`/tenant`, {
    params: {
      include: 'building,agreements,agreements.vendor,agreements.document',
      page,
      pageSize,
    },
  })

  return fromJsonApiWithPagination<Tenant[]>(response.data)
}

export const createAgreement = async (
  tenantId: number,
  name: string,
  location: string
) => {
  const response = await httpClient.post(
    `/tenant/${tenantId}/agreements`,
    {
      name,
      location,
    },
    {
      params: {
        include: 'document',
      },
    }
  )

  return fromJsonApi<TenantAgreement>(response.data)
}

export const deleteAgreement = async (tenantAgreementId: number) => {
  await httpClient.delete(`/tenant/agreements/${tenantAgreementId}`)
}
