import { useQuery } from '@tanstack/react-query'

import { getOrganization } from '../loaders/organizations'
import useAuth from './useAuth'

const useOrganization = () => {
  const { isAuthenticated } = useAuth()

  const { data: organization, isLoading } = useQuery(
    ['organization'],
    getOrganization,
    {
      enabled: isAuthenticated,
      staleTime: 1000, // if many components use the hook in quick succession, do not trigger a new request
    }
  )

  return { organization, isLoading }
}

export default useOrganization
