import { Fragment, useCallback, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'

import Checked from '../components/Checked'
import Filters from '../components/Filters'
import FilterProvider, {
  useFilters,
} from '../components/Filters/FilterProvider'
import NoiCard from '../components/NoiCard/NoiCard'
import { Content, Header, Page, Section, Title } from '../components/Page/Page'
import RecordDetail from '../components/RecordDetail/RecordDetail'
import Heatmap from '../components/Result/Heatmap/Heatmap'
import { useDrawer } from '../components/core/Drawer/DrawerProvider'

const Result = () => {
  const { t } = useTranslation(['dashboard'])
  const { showDrawer, open: drawerOpen } = useDrawer()

  const {
    portfolio,
    timeFilter,
    noiDefinition,
    absolute,
    excludeIncompleteMonths,
    buildingFilters,
  } = useFilters()

  const heatmapRef = useRef()

  const onRecordUpdate = () => {
    heatmapRef.current?.refreshSelectedRow()
  }

  useEffect(() => {
    if (!drawerOpen) {
      heatmapRef.current?.clearSelectedRow()
    }
  }, [drawerOpen])

  const showRecord = useCallback(
    (record) => {
      showDrawer(
        record.text,
        <RecordDetail record={record} onUpdate={onRecordUpdate} />
      )
    },
    [showDrawer]
  )

  return (
    <Checked right="result:read">
      <Page>
        <Header actions={<Filters />}>{t('overview.title')}</Header>
        <Section id="overview">
          <Title>{t('overview.overview.title')}</Title>
          <Content>
            <NoiCard />
          </Content>
        </Section>
        <Fragment>
          {!!portfolio && !!timeFilter && !!noiDefinition && (
            <Heatmap
              ref={heatmapRef}
              portfolio={portfolio}
              noiDefinition={noiDefinition}
              buildingFilters={buildingFilters}
              timeFilter={timeFilter}
              absolute={absolute}
              excludeIncompleteMonths={excludeIncompleteMonths}
              onRecordSelected={showRecord}
            />
          )}
        </Fragment>
      </Page>
    </Checked>
  )
}

const FilterableResult = () => {
  return (
    <FilterProvider>
      <Result />
    </FilterProvider>
  )
}

export default FilterableResult
