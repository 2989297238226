import { useQuery } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'

import AppsGrid from '../components/AppsGrid/AppsGrid'
import Checked from '../components/Checked'
import Page from '../components/Page/LegacyPage'
import { getApps } from '../loaders/apps'

export default function Apps() {
  const { t } = useTranslation(['dashboard'])

  const { data: apps, isLoading } = useQuery(['apps'], getApps)

  if (isLoading) {
    return null
  }

  return (
    <Checked right="app:admin">
      <Page>
        <Page.Header>{t('apps.title')}</Page.Header>
        <Page.Section>
          <AppsGrid apps={apps} />
        </Page.Section>
      </Page>
    </Checked>
  )
}
