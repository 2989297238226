import httpClient from '../utils/httpClient'

export const getNoiDefinitions = async () => {
  const response = await httpClient.get(`/noi_definition/load`)

  return response.data
}

export const createNoiDefinition = async ({ name }) => {
  const response = await httpClient.put(`/noi_definition/create`, { name })

  return response.data
}

export const updateNoiDefinition = async ({ noiDefinitionId, name }) => {
  await httpClient.patch(`/noi_definition/${noiDefinitionId}/update`, { name })
}

export const bulkDeleteNoiDefinitions = async (ids) => {
  return Promise.all(
    ids.map((id) => httpClient.delete(`/noi_definition/${id}/delete`))
  )
}
