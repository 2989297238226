import { useQuery } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import { Link, useNavigate } from 'react-router-dom'

import { GridCard } from '../components/Card/Card'
import Checked from '../components/Checked'
import { Header, Page, Section } from '../components/Page/Page'
import Table from '../components/Table/Table'
import Button from '../components/core/Button/Button'
import { getUsers } from '../loaders/user'

export default function Users() {
  const { t } = useTranslation(['dashboard'])

  const { data: users, isLoading } = useQuery(['users'], getUsers)

  const navigate = useNavigate()

  if (isLoading) {
    return null
  }

  return (
    <Checked right="user:admin">
      <Page>
        <Header
          subtitle={t('users.subtitle') ?? ''}
          actions={
            <>
              <Button
                as={Link}
                to="/admin/users/new"
                variant="primary"
                className="ml-2"
              >
                {t('users.createUser')}
              </Button>
            </>
          }
        >
          {t('users.title')}
        </Header>
        <Section id="grid">
          <GridCard>
            <Table
              isLoading={isLoading}
              headerCells={[
                t('users.column.firstName'),
                t('users.column.lastName'),
                t('users.column.email'),
                t('users.column.role'),
                <span key="actions" className="sr-only">
                  {t('users.column.actions')}
                </span>,
              ]}
              rows={users.map((user) => ({
                data: user,
                cells: [
                  user.firstName,
                  user.lastName,
                  user.email,
                  user.role,
                  <Button
                    key="edit"
                    variant="neutral"
                    onClick={() => navigate(`/admin/users/${user.userId}/edit`)}
                  >
                    {t('users.button.edit')}
                  </Button>,
                ],
              }))}
            />
          </GridCard>
        </Section>
      </Page>
    </Checked>
  )
}
