import { CheckIcon } from '@heroicons/react/20/solid'
import _ from 'lodash'

export default (allNoiDefinitions) => {
  return {
    headerName: 'NOI definitions',
    cellEditor: 'agRichSelectCellEditor',
    cellEditorPopup: true,
    resizable: true,
    minWidth: 500,
    valueGetter: function ({ data }) {
      return data.noiDefinitions.map((noiDefinition) => noiDefinition.name)
    },
    cellEditorParams: function (params) {
      const allNoiDefinitionNames = allNoiDefinitions.map(
        (noiDefinition) => noiDefinition.name
      )

      return {
        values: allNoiDefinitionNames,
        cellRenderer: (props) => {
          const { value } = props

          if (!(typeof value === 'string')) {
            return
          }

          return (
            <span className="absolute inset-y-0 left-1 flex items-center pr-4">
              {value}
              {params.value.includes(value) ? (
                <CheckIcon
                  className="h-5 w-5"
                  aria-hidden="true"
                  fill="green"
                ></CheckIcon>
              ) : (
                <></>
              )}
            </span>
          )
        },
      }
    },
    valueSetter: (params) => {
      const { newValue } = params
      if (newValue) {
        const noiDefinition = allNoiDefinitions.find(
          (noiDefinition) => noiDefinition.name === newValue
        )

        const existingNoiDefinitions = params.data.noiDefinitions
        const existingNoiDefinitionNames = existingNoiDefinitions.map(
          (noiDefinition) => noiDefinition.name
        )

        let newNoiDefinitions = []
        if (existingNoiDefinitionNames.includes(noiDefinition.name)) {
          newNoiDefinitions = existingNoiDefinitions.filter(
            (existingNoiDefinition) =>
              existingNoiDefinition.name !== noiDefinition.name
          )
        } else {
          newNoiDefinitions = _.sortBy(
            [...existingNoiDefinitions, noiDefinition],
            'name'
          )
        }

        params.data.noiDefinitions = newNoiDefinitions

        return true
      }

      return false
    },
  }
}
