import { useMutation } from '@tanstack/react-query'
import { useCallback, useState } from 'react'
import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import Card from '../components/Card/Card'
import CategorySelector from '../components/CategorySelector/CategorySelector'
import Checked from '../components/Checked'
import Page from '../components/Page/LegacyPage'
import Button from '../components/core/Button/Button'
import Input from '../components/core/Input/Input'
import InputField from '../components/core/Input/InputField'
import Label from '../components/core/Input/Label'
import {
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
} from '../components/core/Listbox'
import { createMetric } from '../loaders/metrics'

const fields = [
  {
    id: 'name',
    type: 'text',
    default: {
      value: '',
      label: '',
    },
  },
  {
    id: 'standard',
    type: 'option',
    default: { value: null, label: 'Missing' },
    options: [
      { value: null, label: 'Missing' },
      { value: 'budget', label: 'Budget' },
      { value: 'underwriting', label: 'Underwriting' },
      { value: 'churn', label: 'Churn' },
    ],
  },
]

const Create = () => {
  const { t } = useTranslation(['dashboard', 'translation'])

  const navigate = useNavigate()
  const mutation = useMutation({
    mutationFn: createMetric,
  })

  const [state, setState] = useState(() =>
    fields.reduce(
      (acc, curr) => ({
        ...acc,
        [curr.id]: curr.default,
      }),
      {}
    )
  )

  const [selectedCategory, setSelectedCategory] = useState(null)

  const onSubmit = useCallback(
    async (event) => {
      event.preventDefault()

      const data = {}

      data.categoryId = selectedCategory?.categoryId || null
      for (const [fieldId, field] of Object.entries(state)) {
        data[fieldId] = field.value
      }

      mutation.mutate(data, {
        onSuccess: () => {
          toast.success(t('createMetric.notification.success'))
          navigate('/setup/metrics')
        },
        onError: (error) => {
          toast.error(
            t('createMetric.notification.error', {
              error: error.toString(),
            })
          )
        },
      })
    },
    [state]
  )

  return (
    <Checked right="metric:write">
      <Page.Header>{t('createMetric.title')}</Page.Header>
      <Page.Section>
        <Card>
          <form
            onSubmit={onSubmit}
            className="p-6 space-y-8 divide-y divide-gray-200"
          >
            <div className="space-y-8 divide-y divide-gray-200">
              <div>
                <div>
                  <h3 className="text-lg font-medium leading-6 text-gray-900">
                    {t('createMetric.form.title')}
                  </h3>
                  <p className="mt-1 text-sm text-gray-500">
                    {t('createMetric.form.subtitle')}
                  </p>
                </div>
                <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                  <div key="category" className="sm:col-span-4">
                    <CategorySelector
                      selected={selectedCategory}
                      onChange={setSelectedCategory}
                      topCategoryStandard="root__operating"
                      includeTopCategory={false}
                      allowNull={true}
                    />
                  </div>
                </div>

                <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                  {fields.map((field) => {
                    switch (field.type) {
                      case 'option': {
                        return (
                          <div key={field.id} className="sm:col-span-4">
                            <Listbox
                              value={state[field.id]}
                              onChange={(selected) =>
                                setState((prev) => ({
                                  ...prev,
                                  [field.id]: selected,
                                }))
                              }
                            >
                              {({ open }) => (
                                <>
                                  <Label className="mb-2">
                                    {t(`createMetric.field.${field.id}.title`)}
                                  </Label>
                                  <div className="relative">
                                    <ListboxButton>
                                      {state[field.id].label}
                                    </ListboxButton>
                                    <ListboxOptions isOpen={open}>
                                      {field.options.map((option) => (
                                        <ListboxOption
                                          key={option.label}
                                          value={option}
                                        >
                                          {option.label}
                                        </ListboxOption>
                                      ))}
                                    </ListboxOptions>
                                  </div>
                                </>
                              )}
                            </Listbox>
                          </div>
                        )
                      }
                      default: {
                        return (
                          <div key={field.id} className="sm:col-span-4">
                            <InputField id={field.id}>
                              <Label className="mb-2">
                                {t(`createMetric.field.${field.id}.title`)}
                              </Label>
                              <Input
                                type={field.type}
                                name={field.id}
                                value={state[field.id].value}
                                onChange={(event) =>
                                  setState((prev) => ({
                                    ...prev,
                                    [field.id]: { value: event.target.value },
                                  }))
                                }
                              />
                              <p className="mt-2 text-sm text-gray-500">
                                {t(
                                  `createMetric.field.${field.id}.description`
                                )}
                              </p>
                            </InputField>
                          </div>
                        )
                      }
                    }
                  })}
                </div>
              </div>
            </div>

            <div className="pt-5">
              <div className="flex justify-end">
                <Button onClick={() => navigate('/setup/metrics')}>
                  {t('form.cancel', { ns: 'translation' })}
                </Button>
                <Button className="ml-3" type="submit" variant="primary">
                  {t('form.submit', { ns: 'translation' })}
                </Button>
              </div>
            </div>
          </form>
        </Card>
      </Page.Section>
    </Checked>
  )
}

export default Create
