import { ComponentPropsWithoutRef } from 'react'

import { DocumentCard } from './DocumentCard'
import { UploadCard } from './UploadCard'

export { DocumentCard, UploadCard }

const DocumentList = ({ children }: ComponentPropsWithoutRef<'div'>) => {
  return <div className="grid grid-cols-1 gap-4">{children}</div>
}

export default DocumentList
