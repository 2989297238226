import { useMutation, useQuery } from '@tanstack/react-query'
import { useCallback, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useNavigate } from 'react-router-dom'

import Checked from '../components/Checked'
import MetricSelector from '../components/MetricSelector/MetricSelector'
import Grid from '../components/MetricValues/Grid'
import Page from '../components/Page/LegacyPage'
import Spinner from '../components/Spinner'
import UploadData from '../components/UploadData'
import Button from '../components/core/Button/Button'
import { bulkDelete } from '../loaders/metricValues'
import {
  getMetricValues,
  uploadMetricValuesFile,
} from '../loaders/metricValues'
import { deleteMetric } from '../loaders/metrics'

export default function Metrics() {
  const { t } = useTranslation(['dashboard'])

  const navigate = useNavigate()

  const gridRef = useRef(null)
  const [selectedMetric, setSelectedMetric] = useState(null)

  const {
    data: metricValues,
    isLoading,
    refetch,
  } = useQuery({
    enabled: !!selectedMetric,
    queryKey: ['metric_values', selectedMetric?.metricId],
    queryFn: () => getMetricValues(selectedMetric.metricId),
  })

  const uploadMutation = useMutation({
    mutationFn: uploadMetricValuesFile,
    onSuccess: () => {
      return refetch().then(() => {
        gridRef.current.api.refreshCells()
      })
    },
  })

  const onUpload = useCallback((event) => {
    const files = Array.from(event.target.files)

    uploadMutation.mutate({
      metricId: selectedMetric.metricId,
      file: files[0],
    })
  })

  const deleteMetricMutation = useMutation({
    mutationFn: deleteMetric,
    onSuccess: () => {
      navigate(0)
    },
  })

  const onDeleteMetric = useCallback(async () => {
    if (selectedMetric) {
      deleteMetricMutation.mutate(selectedMetric.metricId)
    }
  })

  const onDeleteMetricValues = useCallback(() => {
    const selection = gridRef.current.api.getSelectedRows()

    bulkDeleteMutation.mutate(selection.map((item) => item.metricValueId))
  }, [])

  const bulkDeleteMutation = useMutation({
    mutationFn: bulkDelete,
    onSuccess: () => {
      return refetch().then(() => {
        gridRef.current.api.refreshCells()
      })
    },
  })

  return (
    <Checked right="metric:write">
      <Page.Header
        actions={
          <>
            <Button variant="negative" onClick={onDeleteMetric}>
              {t('metrics.delete')}
            </Button>
            <Button as={Link} to="/setup/metrics/new" className="ml-2">
              {t('metrics.createMetric')}
            </Button>
          </>
        }
      >
        {t('metrics.title')}
      </Page.Header>
      <Page.Section id="metrics">
        <Page.Section.Content>
          <MetricSelector
            selected={selectedMetric}
            onChange={setSelectedMetric}
          />
        </Page.Section.Content>
        <Page.Section.Content>
          <Button
            variant="negative"
            onClick={onDeleteMetricValues}
            className="ml-2"
          >
            {t('metricValues.deleteSelection')}
          </Button>
          <UploadData onUpload={onUpload} />
        </Page.Section.Content>
        {isLoading ? (
          <div className="flex justify-center">
            <Spinner />
          </div>
        ) : (
          <Grid metricValues={metricValues} ref={gridRef} />
        )}
      </Page.Section>
    </Checked>
  )
}
