import PropTypes from 'prop-types'

export function getSelectedStyling() {
  return {
    strokeWidth: 4,
    color: 'var(--tw-color-blue-700)',
    fontWeight: 'bold',
  }
}

export function getSelectedBudgetStyling() {
  return {
    strokeWidth: 1,
    color: 'var(--tw-color-blue-700)',
    strokeDasharray: '5 5',
    dot: true,
  }
}

export function getTrendStyling() {
  return {
    strokeWidth: 3,
    strokeDasharray: 4,
    color: 'var(--tw-color-black)',
  }
}

export function getAverageStyling() {
  return {
    strokeWidth: 3,
    color: 'var(--tw-color-black)',
    fontWeight: 'bold',
  }
}

export function getDefaultStyling() {
  return {
    strokeWidth: 2,
    color: 'var(--tw-color-grey-1000)',
  }
}

getRandomStyling.propTypes = {
  random: PropTypes.number.isRequired,
}

export function getRandomStyling({ random }) {
  const colors = [
    'var(--tw-color-violet-400)',
    'var(--tw-color-cyan-400)',
    'var(--tw-color-rose-400)',
    'var(--tw-color-emerald-400)',
    'var(--tw-color-lime-400)',
    'var(--tw-color-fuchsia-400)',
    'var(--tw-color-pink-400)',
    'var(--tw-color-green-400)',
  ]

  return {
    strokeWidth: 2,
    color: colors[random],
  }
}

getBuildingsTimelinesStyler.propTypes = {
  buildingId: PropTypes.number.isRequired,
}

export function getBuildingsTimelinesStyler(buildingId) {
  return function getBuildingsTimelinesStyling({
    name,
    building,
    isBudget,
  } = {}) {
    if (building && building.buildingId === buildingId) {
      if (isBudget) {
        return getSelectedBudgetStyling()
      }

      return getSelectedStyling()
    } else if (name === 'Trend') {
      return getTrendStyling()
    } else if (name === 'Average') {
      return getAverageStyling()
    }

    return getDefaultStyling()
  }
}

getChildCategoriesStyler.propTypes = {
  categoryId: PropTypes.number.isRequired,
}

export function getChildCategoriesStyler(categoryId) {
  return function getChildCategoriesStyling({
    category,
    isBudget,
    random,
  } = {}) {
    if (category.categoryId === categoryId) {
      if (isBudget) {
        return getSelectedBudgetStyling()
      }

      return getSelectedStyling()
    }

    return getLineStyling({ category, isBudget, random })
  }
}

export function getLineStyling({ category, isBudget, random }) {
  let styling
  if (category) {
    styling = getCategoryStyling({ category, random })
  } else {
    styling = getRandomStyling({ random })
  }

  if (isBudget) {
    styling.strokeDasharray = '5 5'
    styling.dot = true
  }

  return styling
}

const categoryStandardColors = {
  root__operating__income: 'var(--tw-color-green-900)',
  root__operating__income__rent: 'var(--tw-color-green-700)',
  root__operating__expense: 'var(--tw-color-red-700)',
  root__operating__expense__rent: 'var(--tw-color-red-500)',
  root__operating__expense__resident_churn: 'var(--tw-color-red-300)',
}

export function getCategoryStyling({ category, random }) {
  if (category.standard === 'root__operating') {
    return getSelectedStyling()
  }

  const categoryStandardColor = categoryStandardColors[category.standard]

  if (categoryStandardColor) {
    return {
      color: categoryStandardColor,
    }
  }

  return getRandomStyling({ random })
}
