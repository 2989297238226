import { useCallback, useState } from 'react'
import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import useAuth from '../../hooks/useAuth'
import { loginUser } from '../../loaders/user'
import Button from '../core/Button/Button'
import Input from '../core/Input/Input'
import InputField from '../core/Input/InputField'
import Label from '../core/Input/Label'

const LoginForm = () => {
  const { t } = useTranslation(['login'])
  const auth = useAuth()
  const navigate = useNavigate()

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')

  const onSubmit = useCallback(
    async (event) => {
      event.preventDefault()

      try {
        const response = await loginUser({ email, password })

        auth.login(response, () => {
          navigate('/')
        })
      } catch (error) {
        toast.error(
          t('notification.error', {
            error: error.toString(),
          })
        )
      }
    },
    [email, password]
  )

  return (
    <form className="space-y-6" onSubmit={onSubmit}>
      <InputField id="email">
        <div>
          <Label>{t('form.email')}</Label>
          <div className="mt-1">
            <Input
              name="email"
              type="email"
              autoComplete="email"
              required
              value={email}
              onChange={(event) => setEmail(event.target.value)}
            />
          </div>
        </div>
      </InputField>

      <InputField id="password">
        <div className="space-y-1">
          <Label>{t('form.password')}</Label>
          <div className="mt-1">
            <Input
              name="password"
              type="password"
              autoComplete="current-password"
              required
              value={password}
              onChange={(event) => setPassword(event.target.value)}
            />
          </div>
        </div>
      </InputField>

      <div>
        <Button type="submit" isFull>
          {t('form.signIn')}
        </Button>
      </div>
    </form>
  )
}

export default LoginForm
