import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { getUploadUrl, uploadFile } from '../../loaders/documents'
import DocumentCard from './DocumentCard'

export { DocumentCard }

interface UploadCardProps {
  onUpload: (name: string, location: string) => void
  id: string
}

export const UploadCard = ({ onUpload, id }: UploadCardProps) => {
  const { t } = useTranslation(['dashboard'])

  const onFileChange = useCallback(
    async (event: React.ChangeEvent<HTMLInputElement>) => {
      const file = event.target.files?.[0]
      if (file) {
        // Upload to S3
        const { url, location } = await getUploadUrl(file.name)
        await uploadFile(url, file)

        onUpload(file.name, location)
      }
    },
    []
  )

  return (
    <div className="flex items-center gap-4 justify-center rounded-lg border border-dashed border-gray-900/25 px-3 py-5">
      <div className="flex flex-col items-center">
        <div className="flex text-sm leading-6 text-gray-600">
          <label
            htmlFor={id}
            className="relative cursor-pointer rounded-md bg-white font-semibold text-brand-700 hover:text-brand-600"
          >
            <span>{t('document.upload.action')}</span>
            <input
              id={id}
              name={id}
              type="file"
              className="sr-only"
              accept="application/pdf,image/jpeg"
              onChange={onFileChange}
            />
          </label>
          <p className="pl-1">{t('document.upload.label')}</p>
        </div>
        <p className="text-xs leading-5 text-gray-600">(PDF, JPG up to 10MB)</p>
      </div>
    </div>
  )
}
