import { Organization } from '../models'
import httpClient from '../utils/httpClient'
import { fromJsonApi } from '../utils/jsonapi'

export const getOrganization = async () => {
  const response = await httpClient.get(`/organization`)

  return fromJsonApi<Organization>(response.data)
}

export const updateOrganization = async ({
  name,
  currency,
  senderEmails,
}: Partial<Organization>) => {
  const response = await httpClient.patch(`/organization`, {
    name,
    currency,
    senderEmails,
  })

  return fromJsonApi<Organization>(response.data)
}
