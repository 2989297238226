import { ClockIcon } from '@heroicons/react/24/outline'
import PropTypes from 'prop-types'
import { useState } from 'react'

import TimeFilterProvider from '../../TimeFilterSelector/TimeFilterProvider'
import TimeFilterSelector from '../../TimeFilterSelector/TimeFilterSelector'
import Popover, { PopoverSection, PopoverSections } from '../../core/Popover'

function TimePopover({ timeFilter, setTimeFilter }) {
  const [label, setLabel] = useState('')

  return (
    <TimeFilterProvider
      selected={timeFilter}
      onChange={(newFilter, formattedText) => {
        setTimeFilter(newFilter)
        setLabel(formattedText)
      }}
    >
      <Popover
        text={label}
        icon={<ClockIcon aria-hidden="true" className="h-5 w-5" />}
      >
        <PopoverSections>
          <PopoverSection>
            <TimeFilterSelector />
          </PopoverSection>
        </PopoverSections>
      </Popover>
    </TimeFilterProvider>
  )
}

TimePopover.propTypes = {
  timeFilter: PropTypes.object,
  setTimeFilter: PropTypes.func,
}

export default TimePopover
