import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model'
import { ExcelExportModule } from '@ag-grid-enterprise/excel-export'
import { RichSelectModule } from '@ag-grid-enterprise/rich-select'
import PropTypes from 'prop-types'
import { forwardRef, useCallback, useMemo } from 'react'
import useResizeObserver from 'use-resize-observer'

import { updateAccount } from '../../loaders/accounts'
import AgGridReact from '../Grid/Grid'
import {
  BusinessOrigin,
  Concerning,
  Entry,
  Specifics,
  Type,
} from '../Grid/columns/dynamic/categories'
import Page from '../Page/LegacyPage'
import { Kind, Name, NoiDefinitions, Number } from './columns'

const Grid = forwardRef((data, gridRef) => {
  const { accounts, noiDefinitions, categories } = data

  useResizeObserver({
    ref: document.body,
    onResize: () => {
      if (gridRef.current.api) {
        gridRef.current.api.sizeColumnsToFit({
          defaultMinWidth: 160,
        })
      }
    },
  })

  const onFirstDataRendered = useCallback(() => {
    if (gridRef.current) {
      gridRef.current.api.sizeColumnsToFit({
        defaultMinWidth: 160,
      })
    }
  }, [])

  const gridOptions = useMemo(
    () => ({
      defaultColDef: {
        editable: true,
        suppressMovable: true,
      },
      columnDefs: [
        Name,
        NoiDefinitions(noiDefinitions),
        BusinessOrigin(categories),
        Type(categories),
        Entry(categories),
        Concerning(categories),
        Specifics(categories),
        Number,
        Kind,
      ],
      onCellValueChanged: async function ({ data }) {
        const { accountId, categoryId } = data

        const noiDefinitionIds = data.noiDefinitions.map(
          (noiDefinition) => noiDefinition.noiDefinitionId
        )

        await updateAccount({ accountId, categoryId, noiDefinitionIds })
      },
      rowSelection: 'multiple',
    }),
    [categories, accounts, noiDefinitions]
  )

  return (
    <Page.Section.Content className="ag-theme-alpine">
      <AgGridReact
        domLayout="autoHeight"
        gridOptions={gridOptions}
        modules={[
          ClientSideRowModelModule,
          RichSelectModule,
          ExcelExportModule,
        ]}
        onFirstDataRendered={onFirstDataRendered}
        ref={gridRef}
        rowClass="c-grid-row"
        rowData={accounts}
      />
    </Page.Section.Content>
  )
})

Grid.propTypes = {
  accounts: PropTypes.array.isRequired,
  noiDefinitions: PropTypes.array.isRequired,
  categories: PropTypes.array.isRequired,
}

Grid.displayName = 'Grid'

export default Grid
