import { useMutation, useQuery } from '@tanstack/react-query'
import { useCallback, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useNavigate } from 'react-router-dom'

import AccountChartSelector from '../components/AccountChartSelector/AccountChartSelector'
import Grid from '../components/AccountCharts/Grid'
import BuildingsSelector from '../components/BuildingsSelector/BuildingsSelector'
import Checked from '../components/Checked'
import NoiDefinitions from '../components/NoiDefinitions/NoiDefinitions'
import Page from '../components/Page/LegacyPage'
import Spinner from '../components/Spinner'
import Button from '../components/core/Button/Button'
import { deleteAccountChart, getAccountsData } from '../loaders/accountCharts'
import { getCategories } from '../loaders/categories'
import { getNoiDefinitions } from '../loaders/noiDefinitions'

export default function Accounts() {
  const gridRef = useRef(null)
  const { t } = useTranslation(['dashboard'])

  const navigate = useNavigate()
  const [selectedAccountChart, setSelectedAccountChart] = useState(null)

  const { data: accounts, isLoading: isAccountsLoading } = useQuery(
    {
      queryKey: ['accounts', selectedAccountChart?.accountChartId],
      queryFn: () => getAccountsData(selectedAccountChart),
      enabled: !!selectedAccountChart,
    },
    [selectedAccountChart]
  )

  const { data: categories, isLoading: isCategoriesLoading } = useQuery({
    queryKey: ['categories'],
    queryFn: getCategories,
  })

  const { data: noiDefinitions, isLoading: isNoiDefinitionsLoading } = useQuery(
    {
      queryKey: ['noiDefinitions'],
      queryFn: getNoiDefinitions,
    }
  )

  const mutation = useMutation({
    mutationFn: deleteAccountChart,
    onSuccess: () => {
      navigate(0)
    },
  })

  const onDelete = useCallback(async () => {
    if (selectedAccountChart) {
      mutation.mutate(selectedAccountChart)
    }
  })

  return (
    <Checked right="account_chart:write">
      <Page.Header
        actions={
          <>
            <Button variant="negative" onClick={onDelete}>
              {t('accountCharts.delete')}
            </Button>

            <Button as={Link} to="/setup/account_charts/new" className="ml-2">
              {t('accountCharts.createAccountChart')}
            </Button>
          </>
        }
      >
        {t('accountCharts.title')}
      </Page.Header>
      <Page.Section id="noiDefinitions">
        <Page.Section.Content>
          <NoiDefinitions />
        </Page.Section.Content>
      </Page.Section>
      <Page.Section id="selectedAccountChart">
        <Page.Section.Content>
          <AccountChartSelector
            selected={selectedAccountChart}
            onChange={setSelectedAccountChart}
          />
        </Page.Section.Content>
      </Page.Section>
      {selectedAccountChart && (
        <div>
          <Page.Section id="accountChartBuildings">
            <Page.Section.Content>
              {isAccountsLoading || isNoiDefinitionsLoading ? (
                <div className="flex justify-center">
                  <Spinner />
                </div>
              ) : (
                <BuildingsSelector accountChart={selectedAccountChart} />
              )}
            </Page.Section.Content>
          </Page.Section>
          <Page.Section id="accounts">
            <Page.Section.Content>
              {isAccountsLoading ||
              isNoiDefinitionsLoading ||
              isCategoriesLoading ? (
                <div className="flex justify-center">
                  <Spinner />
                </div>
              ) : (
                <Grid
                  accounts={accounts}
                  noiDefinitions={noiDefinitions}
                  categories={categories}
                  ref={gridRef}
                />
              )}
            </Page.Section.Content>
          </Page.Section>
        </div>
      )}
    </Checked>
  )
}
