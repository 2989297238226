import { Outlet } from 'react-router-dom'

import Page from '../components/Page/LegacyPage'

const Setup = () => {
  return (
    <Page>
      <Outlet />
    </Page>
  )
}

export default Setup
