import PropTypes from 'prop-types'

export default function ChildCategoriesLegend({ payload }) {
  const filteredPayload = payload.filter(
    (line) => !line.payload.data[0].isBudget
  )

  return (
    <div className="container text-center">
      {filteredPayload.map(({ color, payload: { name } }) => {
        return <div style={{ color }} key={name}>{`${name}`}</div>
      })}
    </div>
  )
}

ChildCategoriesLegend.propTypes = {
  payload: PropTypes.array,
}
