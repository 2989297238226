import httpClient from '../utils/httpClient'

export const getApps = async () => {
  const response = await httpClient.get(`/app`)

  return response.data
}

export const getAppConnection = async (appId) => {
  let response
  try {
    response = await httpClient.get(`/app/${appId}`)
  } catch (error) {
    if (error.response.status === 404) {
      return null
    } else {
      throw error
    }
  }

  return response.data
}

export const connect = async ({ appId, config }) => {
  const response = await httpClient.post(`/app/${appId}/connect`, {
    config,
  })

  return response.data
}

export const disconnect = async (appId) => {
  const response = await httpClient.post(`/app/${appId}/disconnect`)

  return response.data
}

export const updateSettings = async ({ appId, settings }) => {
  const response = await httpClient.post(`/app/${appId}/settings`, {
    settings,
  })

  return response.data
}
