import { useMutation, useQuery } from '@tanstack/react-query'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'

import Card from '../components/Card/Card'
import Checked from '../components/Checked'
import { Header, Page, Section } from '../components/Page/Page'
import Button from '../components/core/Button/Button'
import {
  Form,
  FormField,
  FormFieldGrid,
  FormLabel,
  FormSection,
} from '../components/core/Form/Form'
import Select from '../components/core/Form/Select'
import Input from '../components/core/Input/Input'
import { getBuildings } from '../loaders/buildings'
import {
  createVendor,
  deleteVendor,
  getVendor,
  updateVendor,
} from '../loaders/vendors'

const EditVendor = () => {
  const { t } = useTranslation(['dashboard'])
  const navigate = useNavigate()
  const { vendorId: vendorIdParam } = useParams()
  const vendorId = parseInt(vendorIdParam ?? '')

  const [name, setName] = useState('')
  const [buildingId, setBuildingId] = useState<number>()
  const [selectedBuildingOption, setSelectedBuildingOption] = useState<{
    id: number
    name: string
  }>()

  // Load the vendor if we have a vendorId
  useQuery({
    queryKey: ['vendor', vendorId],
    queryFn: () => getVendor(vendorId!),
    enabled: !!vendorId,
    onSuccess: (data) => {
      setName(data.name)
      setSelectedBuildingOption({
        id: data.building.id,
        name: data.building.buildingReference,
      })
    },
  })

  // Load the buildings and set the first one as the default
  const { data: buildings } = useQuery({
    queryKey: ['buildings'],
    queryFn: getBuildings,
  })

  useEffect(() => {
    if (!selectedBuildingOption && buildings && buildings.length > 0) {
      setSelectedBuildingOption({
        id: buildings[0].id,
        name: buildings[0].buildingReference,
      })
    }
  }, [selectedBuildingOption, buildings])

  useEffect(() => {
    if (selectedBuildingOption) {
      setBuildingId(selectedBuildingOption.id)
    }
  }, [selectedBuildingOption])

  // Mutation callbacks
  const { mutate: createMutation } = useMutation({
    mutationFn: createVendor,
    onSuccess: () => {
      navigate('/vendors')
    },
  })

  const { mutate: updateMutation } = useMutation({
    mutationFn: ({ name, buildingId }: { name: string; buildingId: number }) =>
      updateVendor(vendorId, { name, buildingId }),
    onSuccess: () => {
      navigate('/vendors')
    },
  })

  // Form submission handler
  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault()

    if (vendorId) {
      updateMutation({ name, buildingId: buildingId! })
    } else {
      createMutation({ name, buildingId: buildingId! })
    }
  }

  const { mutate: deleteMutation } = useMutation({
    mutationFn: () => deleteVendor(vendorId),
    onSuccess: () => {
      navigate('/vendors')
    },
  })

  return (
    <Checked right="vendor:write">
      <Page>
        <Header
          actions={
            vendorId ? (
              <Button variant="negative" onClick={() => deleteMutation()}>
                {t('editVendor.button.delete')}
              </Button>
            ) : null
          }
        >
          {vendorId ? t('vendors.edit') : t('vendors.create')}
        </Header>
        <Section>
          <Card>
            <Form onSubmit={handleSubmit}>
              <FormSection>
                <FormFieldGrid>
                  <FormField>
                    <FormLabel htmlFor="name">
                      {t('editVendor.form.name')}
                    </FormLabel>
                    <Input
                      id="name"
                      placeholder={t('editVendor.form.namePlaceholder')}
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      required
                    />
                  </FormField>
                  <FormField>
                    <FormLabel htmlFor="buildingId">
                      {t('editVendor.form.building')}
                    </FormLabel>
                    {buildings ? (
                      <Select
                        options={
                          buildings?.map((building) => ({
                            id: building.id,
                            name: building.buildingReference,
                          })) ?? []
                        }
                        selectedOption={selectedBuildingOption}
                        onChange={(option) =>
                          setSelectedBuildingOption(
                            option as { id: number; name: string }
                          )
                        }
                      />
                    ) : null}
                  </FormField>
                </FormFieldGrid>
              </FormSection>
              <div className="mt-6 flex justify-end gap-x-4">
                <Button
                  variant="neutral"
                  onClick={() => navigate('/vendors')}
                  type="button"
                >
                  {t('editVendor.button.cancel')}
                </Button>
                <Button variant="primary" type="submit">
                  {t('editVendor.button.save')}
                </Button>
              </div>
            </Form>
          </Card>
        </Section>
      </Page>
    </Checked>
  )
}

export default EditVendor
