import { useQuery } from '@tanstack/react-query'
import PropTypes from 'prop-types'
import { Fragment, useState } from 'react'
import { useTranslation } from 'react-i18next'

import BarChart from '../components/BarChart/BarChart'
import Checked from '../components/Checked'
import Page from '../components/Page/LegacyPage'
import PortfolioSelector from '../components/PortfolioSelector/PortfolioSelector'
import Spinner from '../components/Spinner'
import { Tooltip } from '../components/Tooltip/Tooltip'
import { getPortfolioAttributeDistribution } from '../loaders/buildings'
import { getNeutralColor } from '../utils/color/result'

const MultiBuildingTooltip = ({ active, payload }) => {
  if (active && payload && payload.length) {
    const { label, value } = payload[0].payload

    return (
      <Tooltip>
        <div className="custom-tooltip">
          <p className="label">{`${label} : ${value}`}</p>
        </div>
      </Tooltip>
    )
  }

  return null
}

MultiBuildingTooltip.propTypes = {
  active: PropTypes.bool,
  payload: PropTypes.array,
}

const SingleBuildingTooltip = ({ active, payload }) => {
  if (active && payload && payload.length) {
    const { label, value } = payload[0].payload

    return (
      <Tooltip>
        <div className="custom-tooltip">
          <p className="label">{`${label} : ${value}`}</p>
          <p className="desc">Building status: Active</p>
        </div>
      </Tooltip>
    )
  }

  return null
}

SingleBuildingTooltip.propTypes = {
  active: PropTypes.bool,
  payload: PropTypes.array,
}

const useAttributeDistribution = ({ attribute, portfolioId }) => {
  return useQuery({
    enabled: !!portfolioId,
    queryKey: ['distribution', attribute, portfolioId],
    queryFn: () =>
      getPortfolioAttributeDistribution(portfolioId, { attribute }),
    staleTime: Infinity,
  })
}

export default function BuildingsDrilldown() {
  const { t } = useTranslation(['dashboard', 'translation'])

  const [portfolio, setPortfolio] = useState()
  const portfolioId = portfolio?.portfolioId

  const { data: sizeData, isLoading: sizeIsLoading } = useAttributeDistribution(
    {
      portfolioId,
      attribute: 'size',
    }
  )

  const { data: unitsData, isLoading: unitsIsLoading } =
    useAttributeDistribution({
      portfolioId,
      attribute: 'units',
    })

  const { data: floorsData, isLoading: floorsIsLoading } =
    useAttributeDistribution({
      portfolioId,
      attribute: 'floors',
    })

  const { data: primaryUsageData, isLoading: primaryUsageIsLoading } =
    useAttributeDistribution({
      portfolioId,
      attribute: 'primaryUsage',
    })

  const { data: constructionYearData, isLoading: constructionYearIsLoading } =
    useAttributeDistribution({
      portfolioId,
      attribute: 'constructionYear',
    })

  const isLoading =
    !portfolioId ||
    sizeIsLoading ||
    unitsIsLoading ||
    primaryUsageIsLoading ||
    floorsIsLoading ||
    constructionYearIsLoading

  return (
    <Checked right="result:read">
      <Page>
        <Page.Header>{t('buildingsDrilldown.title')}</Page.Header>
        <Page.Section id="portfolios">
          <Page.Section.Content>
            <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
              <div key="portfolio" className="sm:col-span-4">
                <PortfolioSelector
                  selected={portfolio}
                  onChange={setPortfolio}
                />
              </div>
            </div>
          </Page.Section.Content>
        </Page.Section>

        {isLoading ? (
          <Spinner isFull />
        ) : (
          <Fragment>
            <Page.Section>
              <Page.Section.Title>Sq. meter size</Page.Section.Title>
              <Page.Section.Content>
                <BarChart
                  data={sizeData}
                  BarChartToolTip={SingleBuildingTooltip}
                  xAxisDataKey="label"
                  primaryDataKey="value"
                  primaryGetFill={getNeutralColor}
                ></BarChart>
              </Page.Section.Content>
            </Page.Section>

            <Page.Section>
              <Page.Section.Title>Building units</Page.Section.Title>
              <Page.Section.Content>
                <BarChart
                  data={unitsData}
                  BarChartToolTip={SingleBuildingTooltip}
                  xAxisDataKey="label"
                  primaryDataKey="value"
                  primaryGetFill={getNeutralColor}
                ></BarChart>
              </Page.Section.Content>
            </Page.Section>

            <Page.Section>
              <Page.Section.Title>Floors</Page.Section.Title>
              <Page.Section.Content>
                <BarChart
                  data={floorsData}
                  BarChartToolTip={SingleBuildingTooltip}
                  xAxisDataKey="label"
                  primaryDataKey="value"
                  primaryGetFill={getNeutralColor}
                ></BarChart>
              </Page.Section.Content>
            </Page.Section>

            <Page.Section>
              <Page.Section.Title>Primary usage count</Page.Section.Title>
              <Page.Section.Content>
                <BarChart
                  data={primaryUsageData}
                  BarChartToolTip={MultiBuildingTooltip}
                  xAxisDataKey="label"
                  primaryDataKey="value"
                  primaryGetFill={getNeutralColor}
                ></BarChart>
              </Page.Section.Content>
            </Page.Section>

            <Page.Section>
              <Page.Section.Title>Construction year count</Page.Section.Title>
              <Page.Section.Content>
                <BarChart
                  data={constructionYearData}
                  BarChartToolTip={MultiBuildingTooltip}
                  xAxisDataKey="label"
                  primaryDataKey="value"
                  primaryGetFill={getNeutralColor}
                ></BarChart>
              </Page.Section.Content>
            </Page.Section>
          </Fragment>
        )}
      </Page>
    </Checked>
  )
}
