import qs, { StringifiableRecord } from 'query-string'

import httpClient from '../utils/httpClient'

interface BuildingFilter {
  id: string
}

interface BuildingFilters {
  primaryUsage?: BuildingFilter
  size?: BuildingFilter
  constructionYear?: BuildingFilter
  floors?: BuildingFilter
  units?: BuildingFilter
}

interface TimeFilter {
  year?: number
  minMonth?: number
  maxMonth?: number
}

export const getOverview = async (
  portfolioId: number,
  noiDefinitionId: number,
  { primaryUsage, size, constructionYear, floors, units }: BuildingFilters,
  { year, minMonth, maxMonth }: TimeFilter,
  absolute: boolean,
  excludeIncompleteMonths: boolean
) => {
  const query: StringifiableRecord = {
    buildingPrimaryUsage: primaryUsage?.id,
    buildingSize: size?.id,
    buildingConstructionYear: constructionYear?.id,
    buildingFloors: floors?.id,
    buildingUnits: units?.id,
  }

  if (year) {
    query.year = year

    if (minMonth && maxMonth) {
      query.minMonth = minMonth
      query.maxMonth = maxMonth
    }
  }

  if (absolute) {
    query.absolute = absolute
  }

  if (excludeIncompleteMonths) {
    query.excludeIncompleteMonths = excludeIncompleteMonths
  }

  const url = qs.stringifyUrl({
    url: `/result/portfolio/${portfolioId}/noiDefinition/${noiDefinitionId}/overview`,
    query,
  })

  const response = await httpClient.get(url)

  return response.data
}
