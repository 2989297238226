import _ from 'lodash'

function getColumnCategories(categories, field) {
  return categories.filter((category) => category.nestingLevelColumn === field)
}

const fieldCategoryIdPathIndex = {
  businessOrigin: 1,
  type: 2,
  entry: 3,
  concerning: 4,
  specifics: 5,
}

function findByFieldValue(categories, field, value) {
  return _.find(categories, (category) => _.get(category, field) === value)
}

function categoryValueSetter(
  field,
  nestingLevel,
  columnCategories,
  allCategories
) {
  return function (params) {
    const {
      newValue,
      data: { category: oldCategory },
    } = params

    let newCategory, newCategoryId
    if (newValue !== undefined) {
      let newCategoryCandidates
      if (newValue != null) {
        // User has set a new value that is not null

        if (nestingLevel === 1) {
          // At the lowest level we can always choose between all the available categories
          newCategoryCandidates = columnCategories.filter(
            (columnCategory) => columnCategory.nestingLevel === 1
          )
        } else {
          if (nestingLevel === oldCategory.nestingLevel) {
            // User is changing category in the same column (with the same nesting level of course)
            newCategoryCandidates = _.filter(
              columnCategories,
              (columnCategory) =>
                // Should not be possible to choose the same category
                columnCategory.categoryId !== oldCategory.categoryId &&
                // Only choose between categories that share the parent of current selection
                columnCategory.parentCategoryId === oldCategory.parentCategoryId
            )
          } else if (nestingLevel > oldCategory.nestingLevel) {
            // User is changing category in a different column with a higher nesting level
            newCategoryCandidates = _.filter(
              columnCategories,
              (columnCategory) =>
                columnCategory.parentCategoryId == oldCategory.categoryId
            )
          } else {
            // User is changing category in a different column with lower nesting level
            newCategoryCandidates = _.filter(
              columnCategories,
              (columnCategory) => {
                const intersection = _.intersection(
                  columnCategory.categoryIdPath,
                  oldCategory.categoryIdPath
                )

                if (
                  intersection.length === 0 ||
                  intersection.length === nestingLevel - 1
                ) {
                  return false
                }

                return true
              }
            )
          }
        }

        newCategory = findByFieldValue(newCategoryCandidates, field, newValue)
        newCategoryId = newCategory.categoryId
      } else {
        // User has set a new value that is null
        if (nestingLevel === 1) {
          // If set at the lowest level, set category to null
          newCategory = null
          newCategoryId = null
        } else if (nestingLevel === oldCategory.nestingLevel) {
          // If user is setting to null at same level as currently selected category, just find parent category
          newCategory = _.find(
            allCategories,
            (category) => category.categoryId === oldCategory.parentCategoryId
          )

          newCategoryId = newCategory.categoryId
        } else {
          // User is setting to null lower nesting level than currently selected category

          // Find parent category on path
          newCategory = _.maxBy(allCategories, (category) => {
            if (category.nestingLevel !== nestingLevel - 1) {
              return -1
            }

            const intersection = _.intersection(
              category.categoryIdPath,
              oldCategory.categoryIdPath
            )

            return intersection.length
          })

          newCategoryId = newCategory.categoryId
        }
      }

      _.set(params.data, 'categoryId', newCategoryId)
      _.set(params.data, 'category', newCategory)

      return true
    }

    return false
  }
}

function categoryValueGetter(field) {
  return function (params) {
    return _.get(params.data.category, field) || null
  }
}

function getParentCategoryId(categoryIdPath, field) {
  const index = _.get(fieldCategoryIdPathIndex, field)

  return categoryIdPath[index - 1]
}

function categoryCellEditorParams(field, columnCategories) {
  return function (params) {
    const { category } = params.data

    const values = []
    if (category) {
      const parentCategoryId = getParentCategoryId(
        category.categoryIdPath,
        field
      )

      for (const columnCategory of columnCategories) {
        if (columnCategory.parentCategoryId === parentCategoryId) {
          values.push(_.get(columnCategory, field))
        }
      }
    }

    values.push(null)

    return { values }
  }
}

export {
  categoryValueSetter,
  categoryValueGetter,
  categoryCellEditorParams,
  getColumnCategories,
}
