import { useCallback, useMemo, useState } from 'react'
import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { Link, useNavigate, useParams } from 'react-router-dom'

import logoURL from '../assets/img/logo_dark.svg'
import Toaster from '../components/ToastProvider'
import Button from '../components/core/Button/Button'
import Input from '../components/core/Input/Input'
import InputField from '../components/core/Input/InputField'
import Label from '../components/core/Input/Label'
import { Switch, SwitchGroup, SwitchLabel } from '../components/core/Switch'
import * as Typography from '../components/core/Typography'
import { confirmUser } from '../loaders/user'

const MIN_PASSWORD_LENGTH = 8

const Logo = () => {
  return (
    <div className="flex flex-shrink-0 items-center justify-center text-white">
      <img className="block h-8 w-auto lg:hidden" src={logoURL} alt={name} />
      <img className="hidden h-8 w-auto lg:block" src={logoURL} alt={name} />
    </div>
  )
}

export const matchPasswords = (password, confirmPassword) => {
  return (
    password.length >= MIN_PASSWORD_LENGTH &&
    confirmPassword.length >= MIN_PASSWORD_LENGTH &&
    password === confirmPassword
  )
}

export default function Confirm() {
  const navigate = useNavigate()
  const { t } = useTranslation(['confirm'])
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [acceptedTerms, setAcceptedTerms] = useState(false)

  const { token } = useParams()
  if (!token) {
    navigate('/login')
  }

  const canSubmit = useMemo(
    () => matchPasswords(password, confirmPassword) && acceptedTerms,
    [password, confirmPassword, acceptedTerms]
  )

  const onSubmit = useCallback(
    async (event) => {
      event.preventDefault()

      try {
        await confirmUser({ token, password })
        navigate('/login')
      } catch (error) {
        toast.error(
          t('notification.error', {
            error: error.toString(),
          })
        )
      }
    },
    [password, confirmPassword, acceptedTerms]
  )

  return (
    <>
      <div className="min-h-screen bg-gray-100 flex flex-col justify-center p-6">
        <Logo />
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <Typography.H2 className="mt-6 text-center">
            {t('title')}
          </Typography.H2>
        </div>

        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
          <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
            <form className="space-y-6" onSubmit={onSubmit}>
              <InputField>
                <Label>{t('form.password')}</Label>
                <div className="mt-1">
                  <Input
                    id="password"
                    name="password"
                    type="password"
                    size="md"
                    autoComplete="new-password"
                    minLength={MIN_PASSWORD_LENGTH}
                    required
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </div>
              </InputField>

              <InputField>
                <Label>{t('form.confirmPassword')}</Label>
                <div className="mt-1">
                  <Input
                    size="md"
                    id="confirm-password"
                    name="confirm-password"
                    type="password"
                    autoComplete="new-password"
                    minLength={MIN_PASSWORD_LENGTH}
                    required
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                  />
                </div>
              </InputField>

              <div className="flex items-start">
                <SwitchGroup as="div" className="flex justify-left">
                  <Switch
                    enabled={acceptedTerms}
                    onChange={() => setAcceptedTerms(!acceptedTerms)}
                    aria-label={t('form.acceptTerms')}
                  ></Switch>
                  <SwitchLabel as="span" className="ml-3 text-sm">
                    {t('form.acceptTerms')}{' '}
                    <Link
                      to="/static/terms-of-service.pdf"
                      className="text-brand-700 hover:text-brand-600"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {t('form.termsLink')}
                    </Link>
                  </SwitchLabel>
                </SwitchGroup>
              </div>

              <div className="flex justify-end">
                <Button type="submit" variant="primary" disabled={!canSubmit}>
                  {t('form.submit')}
                </Button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <Toaster />
    </>
  )
}
