import PropTypes from 'prop-types'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import useAuth from '../hooks/useAuth'

function Checked({ right, children }) {
  const navigate = useNavigate()
  const { isAuthenticated, rights } = useAuth()

  useEffect(() => {
    if (!isAuthenticated || !rights || !rights.includes(right)) {
      navigate('/')
    }
  }, [rights, right])

  return children
}

Checked.propTypes = {
  right: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
}

export default Checked
