import PropTypes from 'prop-types'
import { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import {
  Listbox,
  ListboxButton,
  ListboxLabel,
  ListboxOption,
  ListboxOptions,
} from '../../core/Listbox'

export default function PortfolioSelector({ portfolios, selected, onChange }) {
  const { t } = useTranslation(['dashboard'])

  useEffect(() => {
    if (!selected && portfolios && portfolios.length) {
      onChange(portfolios[0])
    }
  }, [])

  const selectedPortfolio = useMemo(() => {
    if (!portfolios || !selected) {
      return null
    }

    return portfolios.find(
      (portfolio) => portfolio.portfolioId === selected.portfolioId
    )
  }, [selected])

  if (!selectedPortfolio) {
    return null
  }

  return (
    <Listbox
      value={selectedPortfolio.portfolioId}
      onChange={(option) => onChange(option)}
    >
      {({ open }) => (
        <>
          <ListboxLabel>{t('portfolios.selector.label')}</ListboxLabel>
          <div className="relative mt-1">
            <ListboxButton>{selectedPortfolio.name}</ListboxButton>
            <ListboxOptions isOpen={open}>
              {portfolios.map((option) => (
                <ListboxOption key={option.portfolioId} value={option}>
                  {option.name}
                </ListboxOption>
              ))}
            </ListboxOptions>
          </div>
        </>
      )}
    </Listbox>
  )
}

PortfolioSelector.propTypes = {
  portfolios: PropTypes.array.isRequired,
  selected: PropTypes.object,
  onChange: PropTypes.func.isRequired,
}
