import httpClient from '../utils/httpClient'

export const getMetricValues = async (metricId) => {
  const response = await httpClient.get(`/metric_value/metric/${metricId}/all`)

  return response.data
}

export const bulkDelete = async (ids) => {
  return Promise.all(
    ids.map((id) => httpClient.delete(`/metric_value/${id}/delete`))
  )
}

export const uploadMetricValuesFile = ({ metricId, file }) => {
  const formData = new FormData()
  formData.append('file', file)

  return httpClient.post(`/metric_value/metric/${metricId}/upload`, formData)
}
