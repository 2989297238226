import PropTypes from 'prop-types'
import { useNavigate } from 'react-router-dom'

import businessCentralLogo from '../../assets/img/business_central.svg'

const AppsGrid = ({ apps }) => {
  const navigate = useNavigate()

  return (
    <div
      className="grid grid-cols-4 gap-10"
      onClick={() => navigate('/setup/apps/businessCentral')}
    >
      {apps.map((app) => (
        <div
          key={app.id}
          className="flex flex-col items-center justify-center gap-4 h-48 w-48 p-4 bg-white rounded-lg shadow-md hover:shadow-lg cursor-pointer"
        >
          <img
            className="inset-0 h-10 w-10 object-cover rounded-lg"
            src={businessCentralLogo}
            alt={app.name}
          />
          <div className="flex items-center justify-center text-center font-medium text-gray-900">
            {app.name}
          </div>
        </div>
      ))}
    </div>
  )
}

AppsGrid.propTypes = {
  apps: PropTypes.array.isRequired,
}

export default AppsGrid
