import {
  ArrowsPointingOutIcon,
  CurrencyEuroIcon,
  DocumentDuplicateIcon,
  DocumentTextIcon,
  ExclamationCircleIcon,
  QuestionMarkCircleIcon,
  UserIcon,
} from '@heroicons/react/24/outline'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { ComponentPropsWithoutRef, useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import AnomalyDetail from '../components/AnomalyDetail/AnomalyDetail'
import { GridCard } from '../components/Card/Card'
import Checked from '../components/Checked'
import { Header, Page, Section } from '../components/Page/Page'
import Button from '../components/core/Button/Button'
import { useDrawer } from '../components/core/Drawer/DrawerProvider'
import Markdown from '../components/core/Markdown/Markdown'
import { deleteAnomaly, getAnomalies } from '../loaders/anomalies'
import { Anomaly } from '../models'

interface AnomalyIconProps extends ComponentPropsWithoutRef<'svg'> {
  anomaly: Anomaly
}

const AnomalyIcon = ({ anomaly, ...props }: AnomalyIconProps) => {
  switch (anomaly.type) {
    case 'vendor_invoice_outlier':
      return <CurrencyEuroIcon {...props} />
    case 'vendor_invoice_count':
      return <DocumentDuplicateIcon {...props} />
    case 'vendor_invoice_tenant_charges':
      return <UserIcon {...props} />
    case 'vendor_invoice_non_agreement':
      return <DocumentTextIcon {...props} />
    case 'vendor_invoice_agreement_non_coverage':
      return <QuestionMarkCircleIcon {...props} />
    case 'vendor_record_account_spread':
      return <ArrowsPointingOutIcon {...props} />
    default:
      return <ExclamationCircleIcon {...props} />
  }
}

interface AnomalyListProps {
  onClick: (anomaly: Anomaly) => void
}

const AnomalyList = ({ onClick }: AnomalyListProps) => {
  const { t } = useTranslation(['dashboard'])
  const queryClient = useQueryClient()

  const { data: anomalies, isLoading } = useQuery({
    queryKey: ['anomalies'],
    queryFn: getAnomalies,
  })

  const { mutate: deleteAnomalyMutation } = useMutation({
    mutationFn: (anomaly: Anomaly) => deleteAnomaly(anomaly.id),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['anomalies'] })
      queryClient.invalidateQueries({ queryKey: ['sidebar-item-count'] })
    },
  })

  if (isLoading || !anomalies) {
    return
  }

  return (
    <ul role="list" className="divide-y divide-gray-100">
      {anomalies.map((anomaly) => (
        <li
          key={anomaly.id}
          className="flex items-center justify-between gap-x-6 py-3 px-4 cursor-pointer hover:bg-gray-50"
          onClick={() => onClick(anomaly)}
        >
          <div className="min-w-0 flex items-center gap-x-3">
            <AnomalyIcon anomaly={anomaly} className="h-6 w-6 text-gray-400" />
            <div className="flex flex-col items-start gap-x-3">
              <p className="text-sm font-semibold text-gray-700">
                {anomaly.title}
              </p>
              <p className="mt-1 text-sm truncate text-gray-500">
                <Markdown>{anomaly.text}</Markdown>
              </p>
            </div>
          </div>
          <div className="flex flex-none items-center gap-x-4">
            <Button
              variant="neutral"
              onClick={(event) => {
                event.stopPropagation()
                deleteAnomalyMutation(anomaly)
              }}
            >
              {t('followUp.anomaly.dismiss')}
            </Button>
          </div>
        </li>
      ))}
    </ul>
  )
}

const FollowUp = () => {
  const { t } = useTranslation(['dashboard'])
  const { showDrawer } = useDrawer()

  const showAnomaly = useCallback(
    (anomaly: Anomaly) => {
      showDrawer(anomaly.title, <AnomalyDetail anomaly={anomaly} />)
    },
    [showDrawer]
  )

  return (
    <Checked right="anomaly:read">
      <Page>
        <Header subtitle={t('followUp.subtitle') ?? ''}>
          {t('followUp.title')}
        </Header>
        <Section>
          <GridCard>
            <AnomalyList onClick={showAnomaly} />
          </GridCard>
        </Section>
      </Page>
    </Checked>
  )
}

export default FollowUp
