import { useQuery } from '@tanstack/react-query'
import PropTypes from 'prop-types'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { getAllAccountCharts } from '../../loaders/accountCharts'
import {
  Listbox,
  ListboxButton,
  ListboxLabel,
  ListboxOption,
  ListboxOptions,
} from '../core/Listbox'

const useAccountCharts = (options) => {
  const { data: accountCharts, isLoading } = useQuery({
    queryKey: ['accountCharts'],
    queryFn: getAllAccountCharts,
    ...options,
  })

  return {
    accountCharts,
    isLoading,
  }
}

export default function AccountChartSelector({ selected, onChange }) {
  const { t } = useTranslation(['dashboard'])
  const { accountCharts, isLoading } = useAccountCharts({
    onSuccess: (data) => {
      if (!selected) {
        onChange(data[0])
      }
    },
  })

  const selectedAccountChart = useMemo(() => {
    if (!accountCharts || !selected) {
      return null
    }

    return accountCharts.find(
      (accountChart) => accountChart.accountChartId === selected.accountChartId
    )
  }, [selected])

  if (isLoading || !selectedAccountChart) {
    return null
  }

  return (
    <Listbox
      value={selectedAccountChart.accountChartId}
      onChange={(option) => onChange(option)}
    >
      {({ open }) => (
        <>
          <ListboxLabel>{t('accountCharts.label')}</ListboxLabel>
          <div className="relative mt-1">
            <ListboxButton>{selectedAccountChart.name}</ListboxButton>
            <ListboxOptions isOpen={open}>
              {accountCharts.map((option) => (
                <ListboxOption key={option.accountChartId} value={option}>
                  {option.name}
                </ListboxOption>
              ))}
            </ListboxOptions>
          </div>
        </>
      )}
    </Listbox>
  )
}

AccountChartSelector.propTypes = {
  selected: PropTypes.object,
  onChange: PropTypes.func.isRequired,
}
