import { Deserializer } from 'jsonapi-serializer'

import { Pagination } from '../models'

interface JsonApiRelationship {
  data: {
    type: string
    id: string
  }
}

interface JsonApiResource {
  type: string
  id: string
  attributes: Record<string, unknown>
  relationships: Record<string, JsonApiRelationship>
}

interface JsonApiResponse {
  data: JsonApiResource | JsonApiResource[]
  included?: JsonApiResource[]
  meta?: {
    pagination?: Pagination
  }
}

export async function fromJsonApi<T>(data: JsonApiResponse): Promise<T> {
  const deserializer = new Deserializer({
    keyForAttribute: 'camelCase',
  })

  return deserializer.deserialize(data) as T
}

export async function fromJsonApiWithPagination<T>(
  data: JsonApiResponse
): Promise<{
  data: T
  pagination: Pagination
}> {
  const deserializedData = await fromJsonApi<T>(data)
  const pagination = data.meta?.pagination as Pagination

  return {
    data: deserializedData,
    pagination,
  }
}
