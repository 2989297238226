import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react'

import Drawer from './Drawer'

interface DrawerContextType {
  showDrawer: (title: string, content: ReactNode) => void
  hideDrawer: () => void
}

const DrawerContext = createContext<DrawerContextType>({
  showDrawer: (title: string, content: ReactNode) => {}, // eslint-disable-line @typescript-eslint/no-unused-vars
  hideDrawer: () => {},
})

DrawerContext.displayName = 'DrawerContext'

export function useDrawer() {
  return useContext(DrawerContext)
}

export function DrawerProvider({ children }: { children: ReactNode }) {
  const [open, setOpen] = useState(false)
  const [title, setTitle] = useState<string | null>(null)
  const [content, setContent] = useState<ReactNode | null>(null)

  const showDrawer = useCallback(
    (title: string, content: ReactNode) => {
      setTitle(title)
      setContent(content)
      setOpen(true)
    },
    [setTitle, setContent, setOpen]
  )

  const hideDrawer = useCallback(() => {
    setOpen(false)
  }, [setOpen])

  const onClosed = useCallback(() => {
    setTitle(null)
    setContent(null)
  }, [setTitle, setContent])

  const value = useMemo(
    () => ({ showDrawer, hideDrawer }),
    [showDrawer, hideDrawer]
  )

  return (
    <DrawerContext.Provider value={value}>
      <Drawer
        open={open}
        setOpen={setOpen}
        title={title}
        content={content}
        onClosed={onClosed}
      />
      {children}
    </DrawerContext.Provider>
  )
}
