import httpClient from '../utils/httpClient'

export const getMetrics = async () => {
  const response = await httpClient.get(`/metric/all`)

  return response.data
}

export const createMetric = async (data) => {
  return httpClient.post(`/metric/create`, data)
}

export const deleteMetric = async (metricId) => {
  const response = await httpClient.delete(`/metric/${metricId}/delete`)

  return response.data
}
