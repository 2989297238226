import * as Sentry from '@sentry/react'
import { QueryClientProvider } from '@tanstack/react-query'
import assert from 'assert'
import { StrictMode, Suspense } from 'react'
import * as ReactDOM from 'react-dom/client'
import { RouterProvider, createBrowserRouter } from 'react-router-dom'

import AuthProvider from './components/AuthProvider/AuthProvider'
import Spinner from './components/Spinner'
import './i18n'
import routes from './routes'
import './sentry'
import './styles/index.css'
import queryClient from './utils/queryClient'

const REACT_APP_PUBLIC_URL = process.env.REACT_APP_PUBLIC_URL
assert(REACT_APP_PUBLIC_URL, 'No public URL provided to set as basename')

const domContainer = document.getElementById('root')
const root = ReactDOM.createRoot(domContainer)

const sentryCreateBrowserRouter =
  Sentry.wrapCreateBrowserRouter(createBrowserRouter)

const router = sentryCreateBrowserRouter(routes)

root.render(
  <StrictMode>
    <QueryClientProvider client={queryClient}>
      <AuthProvider>
        <Suspense fallback={<Spinner isFull />}>
          <RouterProvider router={router} />
        </Suspense>
      </AuthProvider>
    </QueryClientProvider>
  </StrictMode>
)
