import { useQuery } from '@tanstack/react-query'

import { getSettings } from '../loaders/user'
import useAuth from './useAuth'

export default function useSettings() {
  const { isAuthenticated } = useAuth()

  const { data: settings, isLoading } = useQuery(['settings'], getSettings, {
    enabled: isAuthenticated,
    staleTime: 1000, // if many components use the hook in quick succession, do not trigger a new request
  })

  return { isLoading, settings }
}
