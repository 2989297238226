import PropTypes from 'prop-types'
import { Legend } from 'recharts'
import { Line, LineChart, Tooltip, XAxis, YAxis } from 'recharts'

Chart.propTypes = {
  data: PropTypes.array.isRequired,
  getStyling: PropTypes.func.isRequired,
  yAxisLabelValue: PropTypes.string.isRequired,
  xAxisLabelValue: PropTypes.string.isRequired,
  tooltip: PropTypes.elementType,
  onClick: PropTypes.elementType,
  onDotClick: PropTypes.elementType,
  legend: PropTypes.elementType,
}

export default function Chart({
  data,
  getStyling,
  tooltip,
  onClick,
  onDotClick,
  legend,
  yAxisLabelValue,
  xAxisLabelValue,
}) {
  const lines = () => {
    return data.map((line, idx) => {
      const { name, observations, building, category, isBudget } = line

      // Attach to use building, category and name information in onClick and onDotClick
      // In the future the name should live on the category
      for (const observation of observations) {
        observation['lineName'] = name
        observation['building'] = building
        observation['category'] = category
        observation['isBudget'] = isBudget
      }

      const { color, strokeWidth, strokeDasharray, dot } = getStyling({
        random: idx,
        name,
        building,
        category,
        isBudget,
      })

      return (
        <Line
          name={name}
          key={name}
          data={observations}
          dataKey="y"
          type="linear"
          strokeWidth={strokeWidth}
          stroke={color}
          dot={dot}
          isAnimationActive={false}
          strokeDasharray={strokeDasharray}
          activeDot={
            onDotClick
              ? { onClick: (event, payload) => onDotClick(payload) }
              : undefined
          }
        ></Line>
      )
    })
  }

  return (
    <>
      <LineChart
        width={900}
        height={900}
        margin={{ top: 25, right: 100, left: 50, bottom: 150 }}
        onClick={onClick}
      >
        {legend && <Legend verticalAlign="top" content={legend} />}
        {tooltip && (
          <Tooltip
            content={tooltip}
            cursor={true}
            wrapperStyle={{ outline: 'none' }} // https://github.com/recharts/recharts/issues/2920
          />
        )}
        <XAxis
          fontFamily="sora"
          position="top"
          angle={45}
          textAnchor="right"
          dataKey="xAxisLabel"
          allowDuplicatedCategory={false}
          tick={{ fontSize: 15 }}
          label={{
            dy: 40,
            value: xAxisLabelValue,
            position: 'bottom',
          }}
        />
        <YAxis
          tick={{ fontSize: 15 }}
          width={100}
          label={{
            value: yAxisLabelValue,
            angle: -90,
            position: 'left',
          }}
        />
        {lines()}
      </LineChart>
    </>
  )
}
